import {createAsyncThunk} from "@reduxjs/toolkit";
import { FirestoreApi } from "@services";


export const getMilestones = createAsyncThunk('database/getMilestones', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try{
        const milestones = await FirestoreApi.getMilestones();
        return fulfillWithValue(milestones)
    }catch(error: any){
        throw rejectWithValue(error.message)
    }
});