import {FirestoreApi} from "./firestore-api";
import {Profile} from "@types";
import {DAY_IN_MILLISECONDS} from "@consts";


class notificationService {
    async register() {

    }

    getToken() {
        return '';
    }

    async verifyNotificationAndReturnToken(profile: Profile) {
        let token: any = profile && profile.token;
        let tokenCreated = profile && profile.tokenCreated;
        if((tokenCreated && tokenCreated < (Date.now() - (7 * DAY_IN_MILLISECONDS))) || !tokenCreated) {
            token = null;
        }
        const isGranted = true
        if (!isGranted) {
            await this.register();
            token = await this.getToken();
            await FirestoreApi.updateNotificationToken(token);
        }

        if (!token) {
            token = await this.getToken();
            await FirestoreApi.updateNotificationToken(token);
        }
        return token;
    }
}


export const NotificationService = new notificationService();