import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';

import {
    SignUpScreen,
    SignInScreen,
    HomeScreen,
    BeersScreen,
    BeersUnPurchasedScreen,
    BeersPurchasedScreen,
    BeersWishlistScreen,
    ProfileScreen,
    BeersLeaderboardScreen,
    UsersLeaderboardScreen,
    ProfileViewScreen,
    LeaderboardScreen, ForgotPasswordScreen, EventsScreen, RewardsScreen, SignUpScreenUser,
} from "@screens";

export function AppRouter() {
    return (<Routes>
        <Route path="/" element={<HomeScreen/>}/>
        <Route path="/home" element={<HomeScreen/>}/>
        <Route path="/beers" element={<BeersScreen/>}/>
        <Route path="/unPurchased" element={<BeersUnPurchasedScreen/>}/>
        <Route path="/purchased" element={<BeersPurchasedScreen/>}/>
        <Route path="/wishlist" element={<BeersWishlistScreen/>}/>
        <Route path="/profile" element={<ProfileScreen/>}/>
        <Route path="/events" element={<EventsScreen/>}/>
        <Route path="/rewards" element={<RewardsScreen/>}/>
        <Route path="/leaderboard" element={<LeaderboardScreen/>}>
            <Route index element={<Navigate to="users"/>}/>
            <Route path="rank" element={<ProfileViewScreen/>}/>
            <Route path="users" element={<UsersLeaderboardScreen/>}/>
            <Route path="beers" element={<BeersLeaderboardScreen/>}/>
        </Route>
        <Route path="/signup" element={<SignUpScreen/>}/>
        <Route path="/signin" element={<SignInScreen/>}/>
        <Route path="/signup-user" element={<SignUpScreenUser/>}/>
        <Route path="/forgotpassword" element={<ForgotPasswordScreen/>}/>
    </Routes>);
}