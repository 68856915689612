import React, { useEffect, useState } from 'react';
import {Card, CardContent, CardHeader, CardMedia, Grid, Link, Paper, Typography} from '@mui/material';
import {useSelector} from "react-redux";
import {selectEvents} from "@store";
import {formatDate, formatTime} from "@shared";

// Define a type for your event data
type Event = {
    uid: string;
    name: string;
    date: string;
    description: string;
    url: string;
    thumbnail: string;
};

export function EventsScreen() {
    const events: any = useSelector(selectEvents);

    console.log('events', events);
    return (
        <div>
            <Typography mb={2} mt={2} textAlign="center" color="secondary" variant="h5" gutterBottom>
                Upcoming Events
            </Typography>
            <Grid container p={1} display="flex" flexDirection="column" justifyContent="center">
                {events?.map((event: any) => (
                    <Grid item xs={12} key={event.uid}>
                        <Paper elevation={3} sx={{ background: 'rgba(50, 50, 50, 0.59)', borderRadius: 2 }}>
                            <Card sx={{ background: 'transparent', marginBottom: 2 }}>
                                <CardContent sx={{padding: '10px'}}>
                                    <div style={{width: '100%', borderRadius: '10px', overflow: "hidden", marginBottom: '5px' }}>
                                        <img src={event.thumbnail} style={{ width: '100%' }}/>
                                    </div>
                                    <Typography color="secondary" variant="h5">{event.name}</Typography>
                                    <Typography color="secondary" variant="body1">Date: {formatDate(event.date)}</Typography>
                                    <Typography mb={2} color="secondary" variant="body1">Time: {formatTime(event.date)}</Typography>
                                    <Typography mb={2} color="secondary" variant="subtitle1">{event.description}</Typography>
                                    <Link  href={event.url} target="_blank" rel="noopener noreferrer">
                                        <Typography sx={{ wrapWord: 'break-word'}} color="primary"> {event.url}</Typography>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

