import {useEffect} from "react";
import {FirestoreApi} from "@services";

export function useOnOrdersChange(uid: string, callback: (value: any) => void) {
    useEffect(() => {
        let unsubscribe = ()=> {};
        if (uid) {
            // listen to profile changes in real time and update ui
            unsubscribe = FirestoreApi.ordersChanges(uid, callback);
        }
        return unsubscribe;
    }, [uid]);
}