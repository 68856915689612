import {createAsyncThunk} from "@reduxjs/toolkit";
import {FirestoreApi} from "@services";

export const getCategories = createAsyncThunk('database/categories', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try{
        const categories = await FirestoreApi.getCategories();
        return fulfillWithValue(categories.map(cat => cat.name))
    }catch(error: any){
        throw rejectWithValue(error.message)
    }
});