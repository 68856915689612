import {createSelector, createSlice} from '@reduxjs/toolkit';
import {editProfile, setProfile, signIn, signOut, signUp} from "@store/thunks/auth.methods";
import {AUTH_STATUS} from "@consts";
import {RootState} from "@reduxjs/toolkit/dist/query/core/apiState";
import {firestoreDateToString} from "@shared";

export const initialState = {
    profile: null,
    user: null,
    ready: false,
    loading: true,
    error: null,
    status: AUTH_STATUS.UNKNOWN
}

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action)=> {
            state.user = action.payload;
            state.status = AUTH_STATUS.USER_LOADED;
        },
        // setFullProfile: (state, action)=> {
        //     const profile = firestoreDateToString(action.payload);
        //     state.profile = profile;
        //     state.status = AUTH_STATUS.PROFILE_LOADED;
        //     state.loading = false;
        // },
        logout: (state) => {
            state.user = null;
            state.profile = null;
            state.status  = AUTH_STATUS.LOGGED_OUT;
        },
        appReady: (state) => {
            state.ready = true;
        }
    },
    extraReducers: builder => {
        builder.addCase(setProfile.fulfilled, (state, action)=> {
            state.profile = firestoreDateToString(action.payload);
            state.status = AUTH_STATUS.PROFILE_LOADED;
            state.loading = false;
        })
        builder.addCase(signIn.pending, (state, action) => {
            state.loading = true;
            state.status = AUTH_STATUS.SIGN_IN_STARTED;
        });
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.status = AUTH_STATUS.SIGN_IN_COMPLETE;
        });
        builder.addCase(signIn.rejected, (state, action) => {
            state.loading = false;
            state.status = AUTH_STATUS.SIGN_IN_FAILED;
        });

        builder.addCase(signUp.pending, (state, action) => {
            state.loading = true;
            state.status = AUTH_STATUS.CREATE_PROFILE_STARTED;
        });
        builder.addCase(signUp.fulfilled, (state, action) => {
            state.status = AUTH_STATUS.CREATE_PROFILE_COMPLETE
        });
        builder.addCase(signUp.rejected, (state, action: any) => {
            console.log('user sighed in failed');
            state.loading = false;
            state.error = action.payload?.message;
            state.status = AUTH_STATUS.CREATE_PROFILE_FAILED;
        });

        builder.addCase(signOut.fulfilled, (state, action) => {
            state.status = AUTH_STATUS.SIGN_OUT;
            state.user = null;
            state.profile = null;
        });

        builder.addCase(editProfile.pending, (state, action) => {
            state.status = AUTH_STATUS.EDIT_PROFILE_STARTED
            state.loading = true;
        });
        builder.addCase(editProfile.rejected, (state, action) => {
            console.log('user sighed in failed');
            state.loading = false;
            state.status = AUTH_STATUS.EDIT_PROFILE_FAILED;
        });

        builder.addCase(editProfile.fulfilled, (state, action) => {
            state.status = AUTH_STATUS.EDIT_PROFILE_COMPLETE;
            state.loading = false;
        });
    }
});

export const { setUser, logout, appReady } = authSlice.actions;

// selectors
const selectDomain = (state: RootState<any, any, any>) => state.auth || initialState;

export const selectUser = createSelector(
    [selectDomain],
    (state: any) => state && state.profile
);

export const selectStatus = createSelector(
    [selectDomain],
    (state: any) => state && state.status
);

export const selectAuthLoading = createSelector(
    [selectDomain],
    (state: any) => state && state.loading
);

export const selectAuthErrors = createSelector(
    [selectDomain],
    (state: any) => state && state.status === AUTH_STATUS.EDIT_PROFILE_FAILED ? state.error : null
);

export const selectUid = createSelector(
    [selectDomain],
    (state: any) => state && state.user && state.user.uid
);

export const selectIsReady = createSelector(
    [selectDomain],
    (state: any) => state && state.ready
);

export const authReducer = authSlice.reducer;