import {createTheme} from "@mui/material/styles";

// Define your primary, secondary, and tertiary colors
const colorPrimary = '#f0903a';
const colorSecondary = '#ffffff';
const colorTertiary = '#4c4c4c';

export const theme = createTheme({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    },
    underline: {
        '&:before': {
            borderBottom: '1.2px solid green',
        },
    },
    palette: {
        primary: {
            main: colorPrimary,
            contrastText: "#fff" //button text white instead of black
        },
        secondary: {
            main: colorSecondary,
        },
        tertiary: {
            main: colorTertiary,
        },
        background: {
            default: 'transparent',
        },
    },
    typography: {
        // Use #ffffff color for text
        typography: {
            button: { // Here is where you can customise the button
                fontSize: 16,
                fontWeight: 700,
            },
        },
        body1: {
            color: '#ffffff',
        },
    },
    components: {
        // MuiButton: {
        //     styleOverrides: {
        //             fontWeight: 600, // Customize font weight
        //             fontSize: '16px', // Customize font size
        //     }
        // },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: 'black',
                    // Due to a bug in mui we need to explicitly override media with the default of 48px
                    '@media (min-width: 600px)': {
                        minHeight: '4.8rem',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: colorSecondary, // Set the border color to white
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // #ffffff border for input fields
                root: {
                    borderRadius: '20px !important',
                },
                input: {
                    color: '#ffffff',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Gray icon color
                root: {
                    color: 'gray',
                },
            },
        },
    },
} as any);