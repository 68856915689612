import {useEffect, useRef} from "react";
import {appReady, logout, setUser} from "@store";
import {useDispatch} from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import {firebaseAuth} from "@app-config";
import {unsubscribe} from "diagnostics_channel";


export function useAuthStateChanged() {
    const dispatch = useDispatch();
    const timer = useRef<any>();
    useEffect(() => {
        return onAuthStateChanged(firebaseAuth,(userAuth) => {
            console.log('auth state changed..............>', userAuth);
            timer.current = setTimeout(()=> {
                dispatch(appReady());
            }, 800);
            if (userAuth) {
                clearTimeout(timer.current);
                // user is logged in, send the user's details to redux, store the current user in the state
                dispatch(appReady());
                dispatch(
                    setUser({
                        email: userAuth.email,
                        uid: userAuth.uid,
                        displayName: userAuth.displayName,
                        photoUrl: userAuth.photoURL,
                    })
                );
            } else {
                // dispatch(logout());
            }
        });
    }, []);
}