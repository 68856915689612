import {createTheme} from "@mui/material/styles";
import {outlinedInputClasses} from "@mui/material";

export const colorPrimary = '#f0903a';
export const colorSecondary = '#ffffff';
export const colorTertiary = '#4c4c4c';
export const colorPrimaryLight = '#FFA715';


export const LightTheme = createTheme({
    palette: {
        primary: {
            main: colorPrimary,
        },
        secondary: {
            main: colorSecondary
        },
    },
    headline: {
        color: colorPrimary,
        width: '100%',
        textAlign: 'center'
    },
    components: {
        MuiTextField: {
            defaultProps: {
                color: "secondary",
                inputProps: {
                    style: {
                        color: colorSecondary // This sets the text field font color
                    }
                }
            },
            styleOverrides: {
                root: {
                    '.MuiInputLabel-root': {
                        color: "white"
                    },
                    [`& .${outlinedInputClasses.notchedOutline}`]: {
                        borderWidth: 1,
                        borderColor: "white",
                        color: "white"
                    },
                    [`& .${outlinedInputClasses.input}`]: {
                        color: "white"
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                        color: "white"
                    },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "white",
                            color: "white"
                        },
                },
                'label &': {
                    color: 'white'
                },
                'MuiTextField-root': {
                  borderColor: 'white',
                  color: 'white'
                },
                '& label.Mui-focused': {
                    color: 'white',
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'white',
                    },
                    '&:hover fieldset': {
                        borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'white',
                    },
                },
            }
        }
    }
} as any);

LightTheme.typography.h3 = {
    fontWeight: '400',
    fontSize: '1.2rem',
    marginTop: 10,
    marginBottom: 10,
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [LightTheme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};
