import {Assets} from "@assets";
import {colorPrimary} from "./theme";


export const GlobalStyles = {
    smallImg: {
        width: 100,
    },
    fullWidth: {
       width: '100%'
    },
    sp: {
        padding: 5
    },
    mp: {
        padding: 10
    },
    bp: {
      padding: 15
    },
    sm: {
      margin: 5,
    },
    mm: {
        margin: 10,
    },
    smt: {
      marginTop: 5
    },
    logoMain: (maxWidth = 230, marginTop = 30, marginBottom = 10)=> ({
        alignSelf: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth,
        marginTop,
        marginBottom
    }),
    menuIcon: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%'

    },
    linkButton: {
        textDecorationLine: 'underline',
        color: 'white',
        fontSize: 20,
    },
    appBackground: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'fixed',
        backgroundImage: `url("${Assets.appBackground}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -1
    },
    viewBackground: {
        backgroundImage: `url("${Assets.appBackground}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    homeTopBarWrapper: {
        background: 'rgba(255, 255, 255, 0.15)',
        padding: '15px 10px 5px 10px',
        marginBottom: 50
    },
    homeTopBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignSelf: 'center',
        alignItems: 'flex-start'
    },
    beerName: {
        fontWeight: '800',
    },
    breweryName: {
        fontWeight: '700',
    },
    beerSize: {
        fontWeight: '700',
    },
    beerAbv: {
        fontWeight: '700',
    },
    beerCount: {
        fontWeight: '800',
        color: '#ef9443',
        // position: 'absolute',
        // top: 10,
        // right: 20,
    },
    beerListContainer: {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.78)',
        display: 'flex',
        flexDirection: 'row',
        // borderBottomWidth: 5,
        // borderBottomColor: '#520404',
        alignItems: 'stretch',
        borderBottom: "6px solid #7d2f00",
        boxShadow: "0 3px 1px 0 rgba(255,255,255,0.25), 0 1px 3px 0 rgba(255,255,255,0.25), 0 3px 5px -1px rgba(255,255,255,0.25)"

    },
    addBtn: {
        width: '50%',
        flex: 1,
        backgroundColor: '#ef9443 !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addBtnText: {
        color: 'white',
        fontWeight: '900',
    },
    usersList: {
        container: {
            marginHorizontal: 5,
            marginBottom: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.36)',
        },
        thumbnailSize: {
            width: 70,
            height: 70,
        },
        thumbnail: {
            borderRadius: 35,
            borderWidth: 2,
            borderColor: 'orange',
            overflow: "hidden"
        },
    },
    rewardStarIcon: {
        resizeMode: 'contain',
        width: '100%',
        alignSelf: 'flex-start',
    },
    circlePicLink: (size: number, active: boolean)=> ({
        width: size,
        height: size,
        borderRadius: '50%',
        // borderColor: active ? colorPrimary : '#666',
        backgroundColor: '#323232',
        border: `solid 4px ${active ? colorPrimary : '#666'}`
    })
} as const;