import React from 'react';
import './App.css';
import "cropperjs/dist/cropper.css";
import {GlobalStyles} from "@design";
import {Shell} from "@components";
import {Provider} from 'react-redux';
import {store} from "@store";
import {BrowserRouter} from "react-router-dom";


function App() {
    return (<BrowserRouter>
            <Provider store={store}>
                <div style={GlobalStyles.appBackground} />
                <Shell />
            </Provider>
        </BrowserRouter>)
}

export default App;
