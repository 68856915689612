import React, {useCallback, useMemo, useState} from "react";
import {
    Box,
    Typography,
    IconButton,
    CircularProgress, Grid, Button, Dialog, Modal,
} from "@mui/material";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { getBeers, selectedBeer, selectUser } from "@store"; // Adjust these imports according to your project structure
import { ORDER_STATUS } from "@consts"; // Adjust this import according to your project structure
import { Beer, Profile } from "@types"; // Adjust these imports according to your project structure
import { orderByLocationCondition, truncate } from "@shared";
import {FirestoreApi} from "@services";
import {colorPrimary, GlobalStyles} from "@design";
import {combine} from "@helpers";
import {useSnackbar} from "notistack";
import {BeerDetailsScreen} from "./BeerDetails";
import {Assets} from "@assets"; // Adjust these imports according to your project structure

type BeerItemProps = {
    beer: Beer;
    showCount: boolean;
    totalPending: number;
};

export function BeerItem({
                             beer,
                             showCount,
                             totalPending,
                         }: BeerItemProps) {
    const dispatch = useDispatch();
    const user: Profile = useSelector(selectUser);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const toggleWishlist = useCallback(() => {
        if (beer && beer.wishlist) {
            FirestoreApi.removeFromWishlist(beer.uid)
                .then(() => {
                    enqueueSnackbar(`Beer ${beer.title} removed from wishlist`);
                });
        } else {
            FirestoreApi.addToWishlist(beer.uid)
                .then(() => {
                    enqueueSnackbar(`Beer ${beer.title} added to wishlist`);
                });
        }
    }, [beer]);

    function orderBeer() {
        setIsLoading(true);
        // if(totalPending < 5) {
        //     FirestoreApi.orderBeer(beer, user).then((response)=> {
        //         if(response) {
        //             enqueueSnackbar('Order succeed.');
        //         } else {
        //             enqueueSnackbar('Order failed');
        //         }
        //         // @ts-ignore
        //         dispatch(getBeers());
        //     });
        // } else {
            orderByLocationCondition(beer, user).then((response)=> {
                console.log('passed geo check');
                if(response && response.error) {
                    enqueueSnackbar(response.error);
                } else {
                    enqueueSnackbar('Order succeed.');
                }
                setIsLoading(false);
                // @ts-ignore
                dispatch(getBeers());
            }, ()=> {
                setIsLoading(false);
            });
        // }
    }

    const showBeerDetails = useCallback(() => {
        // @ts-ignore
        setOpen(!open);
        // @ts-ignore
        dispatch(selectedBeer(beer));
        // navigation.navigate("beerProfile", { beer, backNav, totalPending });
    }, [dispatch, beer, totalPending]);

    const beerButton = useMemo(() => {
        let label = (
            <Typography variant="body1" style={GlobalStyles.addBtnText}>
                + ADD
            </Typography>
        );

        if (isLoading || beer.orderStatus === ORDER_STATUS.PENDING) {
            label = <CircularProgress color="secondary" />;
        } else if (beer.orderStatus === ORDER_STATUS.TRIED) {
            label = (
                <Typography variant="body1" style={GlobalStyles.addBtnText}>
                    BEER TRIED
                </Typography>
            );
        }

        return label;
    }, [beer, isLoading]);

    return (
        <>
        <Grid
            container
            sx={GlobalStyles.beerListContainer}
            boxShadow={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom={2}
        >
            <Grid item xs={3}>
                <Button style={{padding: 0}} onClick={showBeerDetails}>
                    <img
                        src={beer.thumbnail || Assets.beerDefault}
                        alt="Beer Thumbnail"
                        style={{ width: 100, height: 100 }}
                    />
                </Button>
            </Grid>
            <Grid item
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="center"
                pl={1}
                xs={5}>
                <Typography
                    onClick={showBeerDetails}
                    variant="body2"
                    style={GlobalStyles.breweryName}
                >
                    {truncate(beer.author, 16)}
                </Typography>
                <Typography
                    onClick={showBeerDetails}
                    variant="body2"
                    style={GlobalStyles.beerName}
                >
                    {truncate(beer.title, 16)}
                </Typography>
                <Typography
                    onClick={showBeerDetails}
                    variant="body2"
                    style={GlobalStyles.beerSize}
                >
                    {beer.sizes}
                </Typography>
                <Typography
                    onClick={showBeerDetails}
                    variant="body2"
                    style={GlobalStyles.beerAbv}
                >
                    ABV {beer.abv}%
                </Typography>
            </Grid>
            <Grid item xs={1} display="flex" flexDirection="column">
                {showCount && (
                    <Typography
                        textAlign="center"
                        pt={1}
                        style={combine(GlobalStyles.beerCount, {marginBottom: 'auto'})}
                        variant="h6"
                    >
                        {beer.counter || 0}
                    </Typography>
                )}
                <IconButton
                    style={{ marginTop: 'auto'}}
                             onClick={() => toggleWishlist()}>
                    {beer.wishlist ?
                        <StarOutlinedIcon  color="primary" fontSize="medium" />
                    :  <StarBorderOutlinedIcon color="primary" fontSize="medium"/>}
                </IconButton>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="stretch">
                <Button
                    sx={{background: colorPrimary + ' !important'}}
                    variant="text"
                    onClick={() => orderBeer()}
                    style={GlobalStyles.addBtn}
                    disabled={beer.orderStatus !== ORDER_STATUS.DEFAULT}>
                    {beerButton}
                </Button>
            </Grid>
        </Grid>
    <Modal
        hideBackdrop={true}
        open={open}>
       <BeerDetailsScreen totalPending={totalPending}
                          handleClose={()=> setOpen(false)}/>
    </Modal>
    </>
    );
}
