import {Box, Typography} from "@mui/material";
import React from "react";

type EmptyMessageProps = {
    img: string; // Change this to the appropriate image URL type
    message: string;
};

export function EmptyMessage({ img, message }: EmptyMessageProps) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={4} // Adjust the spacing as needed
        >
            {img && (
                <Box
                    display="flex"
                    alignItems="center"
                    marginBottom={1} // Adjust the spacing as needed
                >
                    <img
                        src={img}
                        alt="Empty Image"
                        style={{ width: 100 }} // Adjust the style as needed
                    />
                </Box>
            )}
            <Typography variant="h4" color="primary" gutterBottom>
                {message}
            </Typography>
        </Box>
    );
}
