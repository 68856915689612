import {ORDER_STATUS} from "@consts";
import {Beer} from "@types";

export function syncBeersState(beers: any[], state: any): Beer[] {
    const update: any[] = [];
    const {orders, wishlist} = state;

    if (beers.length) {
        beers.forEach((beer: any) => {
            let extendedBeer = {...beer};
            if (extendedBeer.uid && orders && orders[extendedBeer.uid]) {
                extendedBeer.orderStatus = orders[extendedBeer.uid];
            } else {
                try {
                    extendedBeer.orderStatus = ORDER_STATUS.DEFAULT;
                } catch (e) {
                    debugger;
                }
            }
            extendedBeer.wishlist = (wishlist || []).includes(extendedBeer.uid);
            update.push(extendedBeer);
        });
    }
    return update;
}
