import {UserStats} from "@components";
import React from "react";
import {Profile} from "@types";
import {useSelector} from "react-redux";
import {selectUser} from "@store";
import {Grid} from "@mui/material";


export function ProfileViewScreen() {
    const profile: Profile = useSelector(selectUser);
    if (!profile) {
        return null
    }
    return (<Grid mt={30}>
        <UserStats profile={profile}/>
    </Grid>);
}