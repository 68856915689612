import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Fragment } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setMenuOpen, signOut} from "@store";
import {selectMenuOpen} from "@store";
import {GlobalStyles} from "@design";
import {Assets} from "@assets";
import { useNavigate } from 'react-router-dom';
import {Typography} from "@mui/material";
import {Profile} from "@types";
import {ROLE} from "@consts";
type Anchor = 'top' | 'left' | 'bottom' | 'right';


const menuItems = [
    { label: 'Home', route: '/' },
    { label: 'Beer list', route: 'beers' },
    { label: 'Un purchased', route: 'unPurchased' },
    { label: 'Purchased', route: 'purchased' },
    { label: 'Profile', route: 'profile' },
    { label: 'Rewards', route: 'rewards' },
    { label: 'Leaderboard', route: 'leaderboard' },
    { label: 'Wishlist', route: 'wishlist' },
    { label: 'Events', route: 'events' },
    // { label: 'My beer list' },
]


export function AppDrawer() {
    const anchor = 'left';
    const dispatch = useDispatch<any>();
    const menuOpen: boolean = useSelector(selectMenuOpen);
    const user: Profile = useSelector(selectUser);
    const navigate = useNavigate();
    const menuItemList = [...menuItems];
    if(user && user.role && user.role === ROLE.ADMIN) {
        menuItemList.push({ label: 'Signup Form', route: 'signup-user' });
    }
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
            };

    const list = (anchor: Anchor) => (
        <Box
            style={GlobalStyles.viewBackground}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, height: '100%' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <img src={Assets.appLogo} style={{
                ...GlobalStyles.logoMain(100, 20, 10),
                margin: '20px auto',
                display: 'block'
            }} />
            <Divider />
            <List>
                {menuItemList.map((menuItem, index) => (
                    <ListItem key={menuItem.label} disablePadding onClick={()=> {
                        dispatch(setMenuOpen(false));
                        navigate(menuItem.route as string);
                    }}>
                        <ListItemButton>
                            <ListItemText primary={menuItem.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem key="signout" disablePadding onClick={()=> {
                    dispatch(setMenuOpen(false));
                    dispatch(signOut());
                }}>
                    <ListItemButton>
                        <ListItemText>
                            <Typography  color="primary">Sign out</Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Fragment key={anchor}>
                <Drawer
                    anchor={anchor}
                    open={menuOpen}
                    onBackdropClick={()=> dispatch(setMenuOpen(false))}
                    onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
            </Fragment>
        </div>
    );
}
