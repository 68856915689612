import {COLLECTIONS, ORDER_STATUS, STATUS} from "@consts";
import {GetNextRankResponse, SignUpParams} from "@types";
import {isDefined, snapToArray} from "@shared";
import {firebaseApp, firebaseAuth} from "@app-config";
import {db} from "@app-config";
import {collection, getDocs, getDoc, doc, setDoc, serverTimestamp, updateDoc, query, where, limit, orderBy, onSnapshot, arrayUnion, addDoc, arrayRemove } from "firebase/firestore";
import {getDownloadURL, getStorage, ref } from "firebase/storage";
import {getFunctions, httpsCallable} from "firebase/functions";


export class firestoreApi {
    constructor() {
    }

    async getDoc(collectionName: string, path: string) {
        const docRef = doc(db, collectionName, path);
        return getDoc(docRef);
    }

    async getDocs(collectionName: string) {
        return getDocs(collection(db, collectionName));
    }

    async addDoc(collectionName: string, data: any) {
        return addDoc(collection(db, collectionName), data);
    }

    async setDoc(collectionName: string, path: string, data: any) {
        const collectionRef = collection(db, collectionName);
        return setDoc(doc(collectionRef, path), data);
    }

    async updateDoc(collectionName: string, path: string, data: any) {
        const collectionRef = collection(db, collectionName);
        return updateDoc(doc(collectionRef, path), data);
    }

    async query(collectionName: string, condition: any) {
        const q = query(collection(db, collectionName), ...condition);
        return getDocs(q);
    }

    async getConfigValue(key: string) {
        const docRef = doc(db, COLLECTIONS.CONFIG, key);
        const snap = await this.getDoc('config', key);
        return snap.exists() ? snap.data() : null;
    }

    // TODO MOVE TO CLOUD FUNCTIONS!!!
    async updateConfigValue(id: string, key: string, value: any) {
        return this.setDoc(COLLECTIONS.CONFIG, id, {[key]: value})
    }

    async profile(uid: string, withRank = false) {
        const doc = await this.getDoc(COLLECTIONS.PROFILES, uid);
        const profile = doc.exists() ? doc.data() : null;
        if (withRank) {
            await this.addNextRank(profile);
        }
        return profile;
    }

    editProfile(key: string, value: string): any {
        let obj: any = {
            updated: serverTimestamp()
        };
        obj[key] = value;
        return this.updateDoc(COLLECTIONS.PROFILES, firebaseAuth.currentUser?.uid as string, obj)
    }

    async addNextRank(profile: any): Promise<GetNextRankResponse> {
        if (profile && isDefined(profile.point)) {
            const res = await this.query(COLLECTIONS.PROFILES, [
                where('point', '>', profile.point),
                limit(1),
                orderBy('point', 'asc')
            ])

            const doc = res && res.docs && res.docs[0] && res.docs[0].data();
            if (doc) {
                profile.numbeers = profile.point < doc.point ? doc.point - profile.point : 0;
                profile.nextRank = doc && doc.rank || 0;
            }
        }
        return profile;
    }

    // TODO MOVE TO CLOUD FUNCTIONS!!!
    async createProfile(uid: string, data: SignUpParams) {
        return this.setDoc(COLLECTIONS.PROFILES, uid, {
            barman: data.barman || '',
            role: 1,
            uid,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            fullName: `${data.firstName} ${data.lastName}`,
            phone: data.phone,
            birthday: data.birthday.toString(),
            point: 0,
            rank: data.newRank,
            status: STATUS.ACTIVE,
            mileStart: serverTimestamp(),
            created: serverTimestamp(),
            lastactivity: serverTimestamp(),
            thumbnail: '',
        })
    }

    async saveProfileImage(photo: any) {
        // Create a root reference
        const storage = getStorage();
        const savedPictureRef = ref(storage, `/ProfileIDs/${firebaseAuth.currentUser?.uid as any}/profileId.jpeg`);
        const url = await getDownloadURL(savedPictureRef)
       await this.updateDoc(COLLECTIONS.PROFILES, firebaseAuth.currentUser?.uid as string, {
            thumbnail: url,
        })
        return true;
    }

    ordersChanges(uid: string, callback: (orders: any[]) => void) {
        const q = query(collection(db, COLLECTIONS.ORDERS), where('user_id', '==', uid));
        return onSnapshot(q, (snapshot) => {
            const orders = snapshot.docs.map((document: any) => {
                const order = document.data();
                return {
                    beerId: order.beer_id,
                    orderId: order.uid,
                    status: order.status,
                }
            });
            callback(orders);
        });
    }

    async getEventsFooter(): Promise<any> {
        const eventsDoc = await this.query(COLLECTIONS.FOOTER_EVENTS, [orderBy('updated', 'desc')]);
        return snapToArray(eventsDoc);
    }

    async getMilestones(): Promise<any> {
        return this.getDocs(COLLECTIONS.MILESTONES)
            .then((milestonesSnap: any) => snapToArray(milestonesSnap));
    }

    async addToWishlist(beerId: string) {
        await this.updateDoc(COLLECTIONS.PROFILES, firebaseAuth?.currentUser?.uid as string, {
            wishlist: arrayUnion(beerId)
        });
        return true;
    }

    async orderBeer(orderedBeer: any, profile? : any): Promise<boolean> {
       try {
           const snap = await this.query(COLLECTIONS.ORDERS, [
               where('beer_id', '==', orderedBeer.uid),
               where('user_id', '==', firebaseAuth?.currentUser?.uid),
               where('status', '==', ORDER_STATUS.PENDING)
           ]);
           if (snap.size == 0) {
               console.log('new order');
               const newOrder = await this.addDoc(COLLECTIONS.ORDERS, {
                   beer_id: orderedBeer.uid,
                   user_id: firebaseAuth?.currentUser?.uid,
                   userFullName: isDefined(profile) && isDefined(profile.fullName) ? profile.fullName : "unknown",
                   userEmail: isDefined(profile) && isDefined(profile.email) ? profile.email : null,
                   beerName: orderedBeer.title,
                   quantity: 1,
                   barman_id: null,
                   status: ORDER_STATUS.PENDING,
                   userThumbnail: isDefined(profile) && isDefined(profile.thumbnail) ? profile.thumbnail : null,
                   beerThumbnail: orderedBeer.thumbnail,
                   updated: serverTimestamp(),
                   created: serverTimestamp()
               })
               return true;
           } else {
               console.log('prevent duplicate order');
           }
       } catch (e) {
           console.log('order error', e);
       }
       return false;
    }

    async getCategories() {
        const categoriesSnap = await this.query(COLLECTIONS.CATEGORIES, [where('status', '==', STATUS.ACTIVE)])
        return snapToArray(categoriesSnap);
    }

    async removeFromWishlist(beerId: string) {
        await this.updateDoc(COLLECTIONS.PROFILES, firebaseAuth?.currentUser?.uid as string, {
            wishlist: arrayRemove(beerId)
        });
        return true;
    }

    async getEvents() {
        const events = await this.getDocs(COLLECTIONS.EVENTS).then(snapToArray);
        return events.filter((event: any) => {
            const date = new Date(event.date);
            return true;
        });
    }

    async updateNotificationToken(token: string) {
        return;
        // return this.updateDoc(COLLECTIONS.PROFILES, firebaseAuth?.currentUser?.uid as string, {
        //     token: token,
        //     tokenCreated: Date.now()
        // }).then(()=> true)
        //     .catch((err: any)=> {
        //         console.log('save token', err);
        //         return false;
        //     });
    }

    async getPubLocation() {
        const snap = await this.getDoc('config', 'location');
            if(isDefined(snap) && snap.exists()) {
                let objLocation = snap.data();
                if(isDefined(objLocation) && isDefined(objLocation.position)
                    && isDefined(objLocation.position.latitude)
                    && isDefined(objLocation.position.longitude)) {
                    return objLocation.position;
                }
            }
            return null;
    }

    async registerUser(newUserData: any) {
        const functions = getFunctions(firebaseApp);
        // const registerUser = httpsCallable(functions, 'registerUser');
        const registerUser = httpsCallable(functions, "registerUser2");

        const newUser = await registerUser(newUserData);
        return newUser;
    }
}


export const FirestoreApi = new firestoreApi();