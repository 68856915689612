
import appLogo from './applogo.png';
import logoMain from './logo-main.png';
import appBackground from './bg.jpg';
import headerLogo from './hotel_logo.png';
import beer from './beer.png'
import reward from './reward.png';
import beerList from './beer-list.png';
import leaderboard from './leader-board.png';
import events from './events.png';
import beerDefault from './beer-default.png';
import defaultThumbnail from './user-default.jpg';
import leaderBoardRank from './leaderboard-rank.png';
import leaderBoardUsers from './leaderboard-users.png';
import leaderBoardBeer from './leaderboard-beer.png';
import emptyStar from './emptystar.png';
import fullStar from './fullstar.png';
export const Assets = {
    appLogo,
    appBackground,
    logoMain,
    headerLogo,
    beer,
    reward,
    beerList,
    leaderboard,
    events,
    beerDefault,
    defaultThumbnail,
    leaderBoardRank,
    leaderBoardUsers,
    leaderBoardBeer,
    emptyStar,
    fullStar
}