import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Typography,
    Button,
    Grid,
    Paper,
    IconButton, useTheme, CircularProgress,
} from "@mui/material";
import { ORDER_STATUS } from "@consts";
import { FirestoreApi } from "@services";
import Icon from "@mui/material/Icon";
import { orderByLocationCondition } from "@shared";
import { Beer, Profile } from "@types";
import { selectSelectedBeer, selectUser } from "@store";
import { getBeers } from "@store/thunks/get-beers";
import {useSnackbar} from "notistack";
import {Assets} from "@assets";
import {colorPrimary, GlobalStyles} from "@design";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

interface BeerDetailsScreenProps {
    totalPending: number;
    handleClose: ()=> void;
}

export const BeerDetailsScreen: React.FC<BeerDetailsScreenProps> = ({totalPending, handleClose}) => {
    const dispatch = useDispatch<any>();
    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const user: Profile = useSelector(selectUser);
    const beerItem: Beer = useSelector(selectSelectedBeer);
    const [orderStatus, setOrderStatus] = useState(ORDER_STATUS.DEFAULT);

    console.log("BeerDetailsScreen....", beerItem && beerItem.orderStatus);

    useEffect(() => {
        console.log("Beer changed.....>>>>>>>>>");
        if (beerItem && beerItem.orderStatus) {
            setOrderStatus(beerItem.orderStatus);
        }
    }, [beerItem, beerItem && beerItem.orderStatus]);


    // TODO: Handle errors.
    function toggleWishlist(): void {
        if (beerItem && beerItem.wishlist) {
            FirestoreApi.removeFromWishlist(beerItem.uid).then(() => {
                enqueueSnackbar(`Beer ${beerItem.title} removed from wishlist`);
                // dispatch(selectedBeer({...beerItem, wishlist: false}));
            });
        } else {
            FirestoreApi.addToWishlist(beerItem.uid).then(() => {
                enqueueSnackbar(`Beer ${beerItem.title} added to wishlist`);
                // dispatch(selectedBeer({...beerItem, wishlist: true}));
            });
        }
    }

    function orderBeer() {
        if (totalPending && totalPending > 15) {
            enqueueSnackbar(`Max of 15 pending orders exceeded. Total pending beers ${totalPending}`);
        } else {
            orderByLocationCondition(beerItem, user).then((response) => {
                if (response && response.error) {
                    enqueueSnackbar(response.error);
                } else {
                    enqueueSnackbar('Order succeed.');
                }
                dispatch(getBeers());
            });
        }
    }

    const infos = beerItem
        ? [
            { key: "Region", value: beerItem.region },
            { key: "Style", value: beerItem.style },
            { key: "ABV", value: beerItem.abv + "%" },
            { key: "Size", value: beerItem.sizes },
            { key: "Categories", value: beerItem.categories.join(", ") },
        ]
        : [];

    const beerButton = useMemo(() => {
        console.log("Memo changed", orderStatus);
        let label = (
            <Typography variant="h6" color="secondary">
                + ADD
            </Typography>
        );
        if (orderStatus === ORDER_STATUS.PENDING) {
            label = <CircularProgress size={24} color="secondary" />;
        } else if (orderStatus === ORDER_STATUS.TRIED) {
            label = (
                <Typography variant="h6" color="secondary">
                    BEER TRIED
                </Typography>
            );
        }
        return label;
    }, [orderStatus]);

    return beerItem ? (
        <>
            <Paper
                style={{
                    padding: "10px",
                    marginBottom: '2px',
                    width: "100%",
                    flex: 1,
                    ...GlobalStyles.appBackground
                }}
            >
                <Typography
                    textAlign="center"
                    variant="h5"
                    style={{ marginBottom: "10px" }}
                    color="secondary"
                >
                    {beerItem.author}
                </Typography>
                <Typography
                    textAlign="center"
                    variant="h4"
                    style={{ marginBottom: "20px" }}
                    color="secondary"
                >
                    {beerItem.title}
                </Typography>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={5}>
                        <img
                            src={
                                !beerItem.thumbnail || beerItem.thumbnail.includes("assets")
                                    ? Assets.beerDefault
                                    : beerItem.thumbnail
                            }
                            alt="Beer Thumbnail"
                            style={{width: 110, height: 110}}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        {infos.map((info, index) => (
                            <Grid
                                key={index}
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                style={{
                                    marginBottom: "10px",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Grid item xs={5}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginRight: '10px',
                                            paddingBottom: "3px",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            textAlign: "right",
                                        }}
                                        color="secondary"
                                    >
                                        {info.key}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    style={{
                                        backgroundColor: colorPrimary,
                                        display: "flex",
                                        padding: '3px',
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        style={{
                                            paddingBottom: "3px",
                                            textAlign: "left",
                                        }}
                                        color="secondary"
                                    >
                                        {info.value}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Typography
                    variant="body2"
                    style={{ marginBottom: "10px", marginRight: '10px' }}
                    color="secondary"
                >
                    Description:
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "10px", fontWeight: "normal", maxHeight: '450px', overflow: 'auto' }}
                    color="secondary"
                >
                    {beerItem.description}
                </Typography>
                <div style={{ display: "flex", justifyContent: "space-around", position: 'absolute', bottom: 2, left: 0, width: '100%' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => toggleWishlist()}
                        style={{ padding: '15px', margin: '2px', marginBottom: 0 }}
                    >
                        {beerItem.wishlist ?
                            <StarOutlinedIcon  color="secondary" fontSize="medium" />
                            :  <StarBorderOutlinedIcon color="secondary" fontSize="medium"/>}
                    </Button>
                    <Button
                        fullWidth
                        sx={{ padding: '15px', margin: '2px', marginBottom: 0, backgroundColor: colorPrimary + ' !important' }}
                        variant="contained"
                        color="primary"
                        onClick={() => orderBeer()}
                        disabled={beerItem.orderStatus !== ORDER_STATUS.DEFAULT}>
                        {beerButton}
                    </Button>
                </div>
            </Paper>
            <IconButton onClick={()=> handleClose()}>
                <ArrowBackIcon color="secondary" fontSize="medium">
                </ArrowBackIcon>
            </IconButton>
        </>
    ) : null;
};
