class storageService {
    constructor() {
    }

    set(key: string, value: string, ttl?: number) {
        try {
            const item = {
                value,
                ...(ttl && { expiresAt: Date.now() + ttl }), // Include expiresAt property if ttl is provided
            };
            localStorage.setItem(key, JSON.stringify(item));
            return true;
        } catch (e) {
            console.log('storage error', e);
        }
        return false;
    }

    getStringValue(key: string) {
        try {
            const item = localStorage.getItem(key);
            if (item) {
                const parsedItem = JSON.parse(item);
                // Check if the item has expired
                if (parsedItem.expiresAt && parsedItem.expiresAt < Date.now()) {
                    localStorage.removeItem(key);
                    return null;
                }
                return parsedItem.value;
            }
            return null;
        } catch (e) {
            console.log('read error', e);
        }
        return null;
    }

    getObjectValue(key: string) {
        try {
            const item = localStorage.getItem(key);
            if (item) {
                const parsedItem = JSON.parse(item);
                // Check if the item has expired
                if (parsedItem.expiresAt && parsedItem.expiresAt < Date.now()) {
                    localStorage.removeItem(key);
                    return null;
                }
                return parsedItem;
            }
            return null;
        } catch (e) {
            console.log('read error', e);
        }
        return null;
    }

}




export const StorageService = new storageService();