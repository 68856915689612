import {createAsyncThunk} from "@reduxjs/toolkit";
import {EditProfileParams, ForgotPasswordParams, SignInParams, SignUpParams} from "@types";
import {FirestoreApi} from "@services";
import {errorMessage} from "@shared";
import {firebaseAuth} from "@app-config";
import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword} from "firebase/auth";


export const signUp = createAsyncThunk<Promise<any>, SignUpParams, any>('user/signUp', async ({ email, password, barman, firstName, lastName, birthday, phone},{ dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        const {user} = await createUserWithEmailAndPassword(firebaseAuth, email, password as string);
        const uid = user && user.uid;
        // TODO Start remove to cloud functions !!!
        const config = await FirestoreApi.getConfigValue('lastrank');
        const newRank = config && config.lastrank ? config.lastrank + 1 : 99999;
        // request will trigger on profile change hook to set new profile in state.
        if (!uid) {
            console.log('invalid user id.');
            return rejectWithValue(errorMessage('Invalid user id'));
        }
        const newProfile = await FirestoreApi.createProfile(uid, {
            uid,
            email,
            firstName,
            lastName,
            birthday,
            phone,
            newRank,
            barman
        });
        await FirestoreApi.updateConfigValue('lastrank', 'lastrank', newRank);
        //TODO
        // addFeed({
        //   type: NEWS_FEED_TYPE.NEW_USER,
        //   text: getText(NEWS_FEED_TYPE.NEW_USER, {
        //     fullName:
        //         isDefined(signInData.firstName) &&
        //         isDefined(signInData.lastName)
        //             ? `${signInData.firstName} ${signInData.lastName}`
        //             : null,
        //   }),
        // });

        // TODO end remove to cloud functions !!!
        return fulfillWithValue(newProfile);
    } catch (error: any) {
        console.error('signup error', error);
        if (error && error.code === 'auth/email-already-in-use') {
            return rejectWithValue(errorMessage('That email address is already in use!'));
        }

        if (error && error.code === 'auth/invalid-email') {
            return rejectWithValue(errorMessage('That email address is already in use!'));
        }
        if (error.message) {
            return rejectWithValue(errorMessage(error.message));
        }
        return rejectWithValue(errorMessage('Something Went Wrong'));
    }
});


export const signIn = createAsyncThunk<Promise<any>, SignInParams, any>('user/signIn', async ({ email, password}, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        //NativeFirebaseError: [auth/user-not-found]
        const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
        return fulfillWithValue(userCredential.user.toJSON());
    } catch (error: any) {
        console.error('signIn error.....', error);
        if (error && error.code === 'auth/invalid-email') {
            return rejectWithValue(errorMessage('Invalid email'));
        } else if(error && error.code === 'auth/user-not-found') {
            return rejectWithValue(errorMessage('Invalid email or password'));
        }
        rejectWithValue({
            success: false,
            error
        });
    }
});

export const resetPassword = createAsyncThunk<Promise<any>, ForgotPasswordParams, any>('user/forgotPassword', async ({ email}, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        await sendPasswordResetEmail(firebaseAuth, email);
        return fulfillWithValue(true);
    } catch (error: any) {
        if(error && error.code === 'auth/user-not-found') {
            return rejectWithValue(errorMessage('Reset password failed. Invalid email.'));
        }
        return rejectWithValue(errorMessage('Reset password failed. please contact support.'));
    }
});

export const editProfile = createAsyncThunk<Promise<any>, EditProfileParams, any>('user/editProfile', async ({ phone, password, confirmPassword}, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        // TODO check if phone defined.
        if(phone) {
            await FirestoreApi.editProfile('phone', phone);
        }
        if(password && confirmPassword && password === confirmPassword) {
            if(firebaseAuth && firebaseAuth.currentUser) {
                await updatePassword(firebaseAuth.currentUser, password);
            }
        }
        return fulfillWithValue(true);
    } catch (error: any) {
        if(error && error.code === 'auth/user-not-found') {
            return rejectWithValue(errorMessage('Reset password failed. Invalid email.'));
        }
        return rejectWithValue(errorMessage('Reset password failed. please contact support.'));
    }
});

export const setProfile = createAsyncThunk<Promise<any>, any, any>('user/setProfile', async (profile, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    return fulfillWithValue(profile);
});




export const signOut = createAsyncThunk('user/signOut', async (_, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        console.log('logout........')
        await firebaseAuth.signOut();
        return fulfillWithValue(null);
    } catch (error: any) {
        debugger;
        console.error('singout error', error);
        if (error && error.code === 'auth/invalid-email') {
            return rejectWithValue(errorMessage('Invalid email'));
        } else if(error && error.code === 'auth/user-not-found') {
            return rejectWithValue(errorMessage('Invalid email or password'));
        }
        rejectWithValue({
            success: false,
            error
        });
    }
});