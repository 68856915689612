import {createAsyncThunk} from "@reduxjs/toolkit";
import {FirestoreApi} from "@services";

/**
 * TODO change to RTK-Query for caching the result
 */
export const getFooterEvents = createAsyncThunk('database/getFooterEvents', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try{
        const footerEvents = await FirestoreApi.getEventsFooter();
        return fulfillWithValue(footerEvents);
    }catch(error: any){
        return  rejectWithValue(error.message);
    }
});