import {Grid, Typography} from "@mui/material";
import React from "react";
import {Profile} from "@types";


export function UserStats({profile}: {profile: Profile}) {
    return (
        <Grid item container display="flex" justifyContent="space-around" alignItems="center">
            <Grid item display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography color="primary" variant="h4">{profile.point}</Typography>
                <Typography color="primary" variant="h4">Beers</Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography color="primary" variant="h4">{profile.rank}</Typography>
                <Typography color="primary" variant="h4">Rank</Typography>
            </Grid>
        </Grid>
    )
}