
import * as yup from 'yup';
import {diffYears} from "@shared";

export const emailValidation = yup
        .string()
        .email('Please enter valid email')
        .required('Email Address is Required');

export const passwordValidation = yup
    .string()
    .min(6, ({min}) => `Password must be at least ${min} characters`)

export const dateValidation = yup.date()
    .required('Date of birth is required')
    .test('DOB', 'You must be above 21', value => {
        const va =  diffYears(new Date(value), new Date());
        return va > 20;
    })

export const textValidation = (name: string, min = 3, max = 50, required = true) => {
   const schema = yup.string()
        .min(min, ({min}) => `${name} must be at least ${min} characters`)
        .max(max, ({max}) => `${name} must be at at most ${max} characters`);
   return required ? schema.required(`${name} is required`) : schema;
}

export const forgotPasswordSchema = yup.object().shape({
    email: emailValidation,
})

export const signInValidationSchema = yup.object().shape({
    email: emailValidation,
    password: passwordValidation.required('Password is required')
});

export const signUpValidationSchema = yup.object().shape({
    email: emailValidation,
    password: passwordValidation.required('Password is required'),
    confirmPassword: yup.string()
        .required('Confirm password is required')
        .test('passwords-match', 'Passwords must match', function(value) {
            return this.parent.password === value
        }),
    barman: textValidation('barman', 3, 100),
    birthday: dateValidation,
    firstName: textValidation('First name'),
    lastName: textValidation('Last name'),
    phone: textValidation('Phone', 5, 15, false),
});

export const editProfileSchema = yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
    confirmPassword: yup.string()
        .test('passwords-match', 'Passwords must match', function(value) {
            return this.parent.password === value
        }),
    firstName: textValidation('First name'),
    lastName: textValidation('Last name'),
    phone: textValidation('Phone', 5, 15, false),
});
