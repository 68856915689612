import {createAsyncThunk} from "@reduxjs/toolkit";
import {AlgoliaApi} from "@services";

export const getFacets = createAsyncThunk('database/getFacets', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try{
        const facets = await AlgoliaApi.getBeerFacets();
        return fulfillWithValue(facets)
    }catch(error: any){
        throw rejectWithValue(error.message)
    }
});