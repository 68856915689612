import {createUserWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {GlobalStyles} from "@design";
import {combine} from "@helpers";
import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme
} from "@mui/material"; // Grid version 2

import {ForgotPasswordParams} from "@types";
import {ErrorMessage, Form, Formik} from 'formik';
import {forgotPasswordSchema, signInValidationSchema} from "@validators";
import {firebaseAuth} from "@app-config";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {LogoMain} from "@components";
import {Screens} from "@routes";

export function ForgotPasswordScreen() {
    const initialValues: ForgotPasswordParams = {
        email: '',
    };

    const navigate = useNavigate();
    const theme: any = useTheme();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    async function onSubmit({email}: ForgotPasswordParams) {
        await sendPasswordResetEmail(firebaseAuth, email)
            .then(() => {
                navigate(`/${Screens.SignIn}`)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    return (
        <Formik
            validationSchema={forgotPasswordSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {({handleChange, handleBlur, handleSubmit, values, errors, touched, isValid, setFieldValue}) => (
                <Grid container spacing={2} columns={1}>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <LogoMain />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">

                        <Form style={combine(GlobalStyles.bp, GlobalStyles.fullWidth)}>
                            <Grid mb={2}>
                                <TextField fullWidth
                                           label="Email"
                                           id="email"
                                           type="email"
                                           onChange={handleChange}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <EmailOutlinedIcon color="secondary"/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                />
                            </Grid>

                            <Grid mb={4}>
                                <Button sx={{borderRadius: 20, height: 50}} color="primary" variant="contained" fullWidth onClick={() => handleSubmit()}>
                                    <Typography fontWeight={600} fontSize={18}>Reset Password</Typography>
                                </Button>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>

            )}
        </Formik>
    );
}