import React, {useState} from "react";
import {UsersLeaderboard} from "@components";
import {Link, Outlet } from "react-router-dom";
import {GlobalStyles} from "@design";
import {ButtonBase, Grid} from "@mui/material";
import {Assets} from "@assets";

export function LeaderboardScreen() {
    const [selectedRoute, setSelectedRoute] = useState('leaderBoardUsers');
   return  (
       <>
          <Grid container mt={4} mb={4} display="flex" alignItems="center" justifyContent="center">
              <Link to="rank" onClick={()=> setSelectedRoute('leaderBoardRank')}>
                  <Grid item sx={GlobalStyles.circlePicLink(90, selectedRoute === 'leaderBoardRank')}>
                      <img src={Assets.leaderBoardRank} style={{width: '100%'}}/>
                  </Grid>
              </Link>
              <Link to="users" onClick={()=> setSelectedRoute('leaderBoardUsers')}>
                  <Grid item mr={2} ml={2}  sx={GlobalStyles.circlePicLink(110, selectedRoute === 'leaderBoardUsers')}>
                      <img src={Assets.leaderBoardUsers} style={{width: '100%'}}/>
                  </Grid>
              </Link>
              <Link to="beers" onClick={()=> setSelectedRoute('leaderBoardBeer')}>
                  <Grid item sx={GlobalStyles.circlePicLink(90, selectedRoute === 'leaderBoardBeer')}>
                      <img src={Assets.leaderBoardBeer} style={{width: '100%'}}/>
                  </Grid>
              </Link>
          </Grid>
          <Outlet />
       </>
   );
}