import {createAsyncThunk} from "@reduxjs/toolkit";
import {FirestoreApi} from "@services";


export const getEvents = createAsyncThunk('database/getEvents', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        const events: any[] = await FirestoreApi.getEvents();
        return fulfillWithValue(events);
    } catch(error: any) {
        throw rejectWithValue(error.message);
    }
});