import algoliasearch from "algoliasearch";
import {ALGOLIA_APP_ID, ALGOLIA_KEY, BEERS_INDEX, PROFILES_INDEX, ROLE, STATUS} from "@consts";
import {isDefined} from "@shared";
import {Beer, Profile, ProfileResult} from "@types";

const client = algoliasearch(
    ALGOLIA_APP_ID,
    ALGOLIA_KEY
);

export class algoliaApi {
    constructor() {
    }

    async getAllActiveBeers(): Promise<Beer[]> {
        let filters = `(status = ${STATUS.ACTIVE})`;
        const beers: any = await client.initIndex(BEERS_INDEX).search('', {
            filters,
            hitsPerPage: 1000,
        });
        return beers && beers.hits || [];
    }


    async getBeerFacets() {
        const response: any = {};
        const result = await client.initIndex(BEERS_INDEX).search('', {
            facets: ['style', 'region', 'author'],
            maxValuesPerFacet: 1000
        });
        const facets = result && result.facets;

        for (let key in facets) {
            response[key] = Object.keys(facets[key]);
        }
        return response;
    }

    async getTop20Profiles(page: number = 0): Promise<ProfileResult> {
        const response: ProfileResult = {
            users: [],
            page: 0,
            nbPages: 0
        }
        const leadProfiles = await client.initIndex(PROFILES_INDEX)
            .search('', {
                filters: `(status = ${STATUS.ACTIVE})`,
                attributesToRetrieve: ['point', 'rank', 'fullName', 'uid', 'thumbnail'],
                hitsPerPage: 20,
                page: page
            })

        if (isDefined(leadProfiles) && isDefined(leadProfiles.hits)) {
            // @ts-ignore
            response.users = leadProfiles.hits;
            response.page = leadProfiles.page;
            response.nbPages = leadProfiles.nbPages;
        }
        return response;
    }

    async getBarmen(): Promise<Profile[]> {
        let response: any = [];
        try {
            const barmen = await client.initIndex(PROFILES_INDEX).search('', {
                filters: `(status = ${STATUS.ACTIVE}) AND (role = ${ROLE.BARMAN})`,
                attributesToRetrieve: ['fullName', 'uid'],
                hitsPerPage: 1000,
                page: 0,
            });
            response = isDefined(barmen) && isDefined(barmen.hits) && barmen.hits || [];
        } catch (error) {
            throw error;
        }
        return response;
    }

    multiAttribute(key: string, filter: any) {
        let query = '';
        if (isDefined(filter) && isDefined(filter[key])
            && filter[key].length) {
            let catStr = '(';
            for (let i = 0; i < filter[key].length; i++) {
                let value = filter[key][i].replaceAll(filter[key][i], ' ', ' ');
                catStr = i == filter[key].length - 1 ?
                    catStr = `${catStr} ${key}:"${value}"` :
                    catStr = `${catStr} ${key}:"${value}" OR `;
            }
            query = `${catStr})`;
        }
        return query;
    }

    /**
     * ALGOLIA SEARCH.
     * @param freeText
     * @param filter
     * @param paging
     * @returns {Promise<T>}
     */
    //TODO implement the pagination.
    async search(freeText: string = '', filter?: any, paging?: any) {
        let filters = `(status = ${STATUS.ACTIVE})`;
        if (isDefined(filter.abv) && isDefined(filter.abv.lower)
            && isDefined(filter.abv.upper)) {
            filters = `${filters} AND (abv: ${filter.abv.lower} TO ${filter.abv.upper})`;
        }

        let categories = this.multiAttribute('categories', filter);
        filters = categories.length > 0 ? `${filters} AND ${categories} ` : filters;

        let style = this.multiAttribute('style', filter);
        filters = style.length > 0 ? `${filters} AND ${style} ` : filters;

        let region = this.multiAttribute('region', filter);
        filters = region.length > 0 ? `${filters} AND ${region} ` : filters;

        let author = this.multiAttribute('author', filter);
        filters = author.length > 0 ? `${filters} AND ${author} ` : filters;

        // IF SAME QUERY WE WANT TO SHOW MORE RESULTS.
        if (isDefined(paging) && isDefined(paging.params) &&
            paging.params == `${freeText}${filters}` && isDefined(paging.page)
            && isDefined(paging.nbPages) && paging.page < paging.nbPages) {
            paging.page++;
        }
        let QUERY: any = {};
        if (isDefined(freeText) && freeText.length > 0) {
            QUERY.query = freeText;
        }
        QUERY.filters = filters;
        QUERY.hitsPerPage = isDefined(paging) && isDefined(paging.hitsPerPage) ? paging.hitsPerPage : 20;
        QUERY.page = isDefined(paging) && isDefined(paging.page) ? paging.page : 0;
        const results = await client.initIndex(BEERS_INDEX).search('', QUERY);
        results.params = `${freeText}${filters}`;
        return results;
    }
}


export const AlgoliaApi = new algoliaApi();