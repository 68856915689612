import {useEffect} from "react";
import {COLLECTIONS} from "@consts";
import {FirestoreApi} from "@services";
import {db} from "@app-config";
import {doc, onSnapshot} from "firebase/firestore";
import {Unsubscribe} from "@firebase/firestore";

export function useOnProfileChange(uid: string, callback: (value: any) => void) {
    useEffect(() => {
        let unsubscribe: Unsubscribe = ()=> {};
        if (uid) {
            // listen to profile changes in real time and update ui
            unsubscribe = onSnapshot(doc(db, COLLECTIONS.PROFILES, uid), async (snap) => {
                const profile = snap.exists() ? snap.data() : null;
                await FirestoreApi.addNextRank(profile);
                callback(profile);
            });
        }
        return ()=> unsubscribe();
    }, [uid]);
}