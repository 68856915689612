import {Screens} from "@routes";

const securedRoutes = [
    Screens.Home,
    Screens.Beers,
    Screens.Leaderboard,
    Screens.Rewards,
    Screens.Events,
    Screens.Profile,
    Screens.Purchased,
    Screens.UnPurchased,
    Screens.Wishlist,
    Screens.Profile,
    Screens.RegisterForm
]

const hideMenuRoutes = [
    Screens.RegisterForm
]

function replaceAll(inputString: string, targetChar: string, replacementChar: string) {
    // Create a regular expression with the target character
    const regex = new RegExp(targetChar, 'g');

    // Use the replace method to replace all occurrences
    const resultString = inputString.replace(regex, replacementChar);

    return resultString;
}

export function isSecuredRoute(pathname: string) {
    const routes = pathname.split('/');
    const route = routes && routes[1] || routes[0] || '';
    return !route || securedRoutes.includes(route);
}

export function hideMenu(pathname: string) {
    const routes = pathname.split('/');
    const route = routes && routes[1] || routes[0] || '';
    return hideMenuRoutes.includes(route);
}