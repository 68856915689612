import React from "react";
import "cropperjs/dist/cropper.css";
import {Grid, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {Profile} from "@types";
import {selectUser} from "@store";
import {colorPrimary} from "@design";
import {getDateFormat} from "@shared";
import {UserStats} from "@components";

export function ProfileScreen() {
    const profile: Profile = useSelector(selectUser);

    if (!profile) {
        return null
    }
    return (
        <Grid mt={10} container display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Grid mb={2} item style={{
                borderRadius: '50%',
                border: 'solid 4px ' + colorPrimary,
                overflow: 'hidden',
                height: 150,
                width: 150
            }}>
                <img style={{width: '100%'}} src={profile.thumbnail}/>
            </Grid>
            <Grid mb={4} item display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="body1">User Since</Typography>
                <Typography variant="body1"> {getDateFormat(profile.created, 'MMMM yyyy')}</Typography>
            </Grid>

            {profile.lastactivity && (
                <Grid mb={4} item display="flex" flexDirection="column"  justifyContent="center" alignItems="center">
                    <Typography variant="body1">Last Activity</Typography>
                    <Typography variant="body1"> {getDateFormat(profile.lastactivity, 'dd MMM yyyy')}</Typography>
                </Grid>
            )}


          <UserStats profile={profile}/>

        </Grid>
    );
};