import {FirestoreApi} from "./firestore-api";

class _GeolocationService {
    private safariPos: any = null;
    private position: any = {};
    private userPosition: any = null;
    private userPositionDate: any = null;
    constructor() {}

    // Check if geolocation is supported by the browser
    isGeolocationSupported() {
        return 'geolocation' in navigator;
    }

    async isPermissionQuery() {

    }


    async safariLocationPermission() {
        return new Promise((resolve, reject)=> {
            navigator.geolocation.getCurrentPosition((pos)=> {
                this.safariPos = pos;
                console.log('safari safariLocationPermission getCurrentPosition', pos);
                resolve(true);
            }, (error)=> {
                console.log('safariLocationPermission ERRROR', error);
                resolve(null);
            })
        })
    }

    // Check if the user has granted permission for geolocation
    async isPermissionGranted() {
            return this.safariLocationPermission();
    }

    // Request permission for geolocation
    async askForPermission() {
        return new Promise(async (resolve, reject)=> {
            try {
                if(navigator.permissions) {
                    const status = {state: 'prompt'};
                    if (status.state === 'prompt') {
                        await navigator.geolocation.getCurrentPosition((result) => {
                            return resolve(result.coords);
                        });
                    }
                } else {
                    return reject({error: 'permissions not supported...'});
                }
                return resolve(null);
            } catch (error) {
                const status = await this.safariLocationPermission();
                console.log('safariLocationPermission ', status);
                console.error('Error requesting geolocation permission:', error);
                resolve(status);
            }
        })
    }

    // Get the user's current location
    async getCurrentLocation() {
        if (this.isGeolocationSupported()) {
            const isPermissionGranted = await this.isPermissionGranted();
            console.log('isPermissionGranted', isPermissionGranted);
            if (isPermissionGranted) {
                console.log('getting grant');
                try {
                    const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                        if (this.safariPos) {
                           return resolve(this.safariPos);
                        }
                        navigator.geolocation.getCurrentPosition((pos)=> {
                            console.log('position....', pos);
                            this.safariPos = pos;
                            resolve(pos);
                        }, reject);
                    });
                    console.log('your position is ', position);
                    return {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                } catch (error) {
                    console.error('Error getting current location:', error);
                    return null;
                }
            } else {
                console.log('Permission not granted');
                // Permission not granted
                return null;
            }
        } else {
            console.log('Geolocation not supported');
            // Geolocation not supported
            return null;
        }
    }

    deg2rad(deg: any) {
        return deg * (Math.PI/180)
    }
    getDistanceFromLatLonInKm(lat1: any, lon1: any ,lat2: any, lon2: any) {
        let R = 6371; // Radius of the earth in km
        let dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        let dLon = this.deg2rad(lon2-lon1);
        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        let d = R * c; // Distance in km
        return d;
    }

    async getUserLocation() {
        if(this.userPosition && this.userPositionDate && this.userPositionDate > Date.now()) {
            return this.userPosition;
        }
        try {
            this.userPosition = await this.getCurrentLocation();
            if(this.userPosition) {
                this.userPositionDate = Date.now() + (2 * 60000);
            } else {
                await this.askForPermission();
                this.userPosition = await this.getCurrentLocation();
                this.userPositionDate = Date.now() + (2 * 60000);
            }
        } catch (e) {
            console.log('getUserLocation:maybe safari', e);
            let isGranted = await this.isPermissionGranted();
            if(!isGranted) {
                await this.askForPermission();
            }
            try {
                this.userPosition = await this.getCurrentLocation();
                if(this.userPosition) {
                    this.userPositionDate = Date.now() + (2 * 60000);
                }
            } catch (e) {
                console.log('error', e, this.userPosition);
            }
        }
        return this.userPosition;
    }

    async getPubLocation(): Promise<void> {
        if(!this.position || !Object.keys(this.position).length) {
            this.position = await FirestoreApi.getPubLocation();
        }
    }
    async isInLocation() {
        await this.getPubLocation();
        const userLocation: any = await this.getUserLocation();
        let distance = this.getDistanceFromLatLonInKm(
            this.position.latitude,
            this.position.longitude,
            userLocation.latitude,
            userLocation.longitude
        );
        return  distance <= 0.7;
    }
}

export const GeolocationService = new _GeolocationService();


console.log('geo version 5');