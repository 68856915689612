import {useDispatch, useSelector} from "react-redux";
import {selectFooterEvents, selectUser} from "@store";
import {Profile} from "@types";
import React, {useEffect, useMemo} from "react";
import {getFooterEvents} from "@store/thunks/get-footer-events";
import {Box, Button, Container, Grid, Paper, Typography} from "@mui/material";
import {GlobalStyles } from "@design";
import {Assets} from "@assets";
import Marquee from "react-fast-marquee";
import {useNavigate} from "react-router-dom";
import {Screens} from "@routes";
import { getFunctions, httpsCallable, httpsCallableFromURL } from "firebase/functions";
import {firebaseApp, firebaseAuth} from "@app-config";

export function HomeScreen() {
    const dispatch = useDispatch<any>();
    const user: Profile = useSelector(selectUser);
    const footerEvents: any = useSelector(selectFooterEvents);
    const navigate = useNavigate();

    useEffect(()=> {
        dispatch(getFooterEvents());
    }, []);

    //TODO remove memo
    const footerEventsText = useMemo(()=> {
        const events = (footerEvents || []).map((event: any) => event.description).join('   ∙∙∙   ');
        return events.length ? <Typography variant="h6" padding="5" color="secondary">{events}</Typography> : '';
    }, [footerEvents]);

    // if(!user || !user.uid) {
    //     return null;
    // }

    return (
        <Container sx={{padding: 0, background: 'transparent'}}>
            <Box sx={{ marginTop: 0, background: 'transparent' }}>
                <div style={GlobalStyles.homeTopBarWrapper}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        <Grid item alignItems="center" textAlign="center">
                            <Typography color="secondary" variant="h5">Welcome</Typography>
                            <Typography color="primary" variant="h4">{user?.firstName}</Typography>
                        </Grid>
                        <Grid item alignItems="center" textAlign="center">
                            <Typography color="primary" variant="h4">{user?.rank}</Typography>
                            <Typography color="secondary" variant="subtitle1">Rank</Typography>
                        </Grid>
                        <Grid item alignItems="center" textAlign="center">
                            <Typography color="primary" variant="h4">{user?.point}</Typography>
                            <Typography color="secondary" variant="subtitle1">Beers</Typography>
                        </Grid>
                        <Grid item>
                            <img src={Assets.beer} alt="Beer" style={{ maxWidth: 40 }} />
                        </Grid>
                    </Grid>
                    <Grid container display="flex" justifyContent="center" alignItems="center">
                        <Typography color="primary" variant="subtitle1">{user?.numbeers} left to rank {user?.nextRank}</Typography>
                    </Grid>
                </div>
            </Box>

            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                    <Button onClick={() => navigate(`/${Screens.Rewards}`)}>
                        <img src={Assets.reward} alt="Reward" style={{ width: '100%' }} />
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => navigate(`/${Screens.Beers}`)}>
                        <img src={Assets.beerList} alt="Beer List" style={{ width: '100%'  }} />
                    </Button>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={6}>
                    <Button onClick={() => {
                        // navigate(`/${Screens.Leaderboard}`)
                    }}>
                        <img src={Assets.leaderboard} alt="Leaderboard" style={{  width: '100%' }} />
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => navigate(`/${Screens.Events}`)}>
                        <img src={Assets.events} alt="Events" style={{  width: '100%'  }} />
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ backgroundColor: '#00000066', borderTop: '2px solid #4c4c4c', position: 'absolute', bottom: 0 }}>
                <Marquee>
                    <div style={{padding: 5}}>
                        {footerEventsText}
                    </div>
                </Marquee>
            </Box>
        </Container>
    );
}