export const Screens = {
    Home: 'home',
    SignIn: 'signin',
    SignUp: 'signup',
    SignOut: 'signout',
    ForgotPassword: 'forgotpassword',
    Beers: 'beers',
    UnPurchased: 'unPurchased',
    Purchased: 'purchased',
    Wishlist: 'wishlist',
    Profile: 'profile',
    Leaderboard: 'leaderboard',
    Rewards: 'rewards',
    Events: 'events',
    RegisterForm: 'signup-user'
}
