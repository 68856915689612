import { configureStore } from '@reduxjs/toolkit';
import {authReducer} from "./features/authSlice";
import {databaseReducer} from "./features/databaseSlice";
import {beerOrdersReducer} from "./features/beerOrdersSlice";
import {generalReducer} from "./features/generalSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        beerOrders: beerOrdersReducer,
        database: databaseReducer,
        general: generalReducer,
    },
});