export const FIREBASE_API_KEY = 'AIzaSyCu5r3DgewRCl3zwh34Bn0nxgsEDMxlljI';
export const FIREBASE_AUTH_DOMAIN ='pub365-aec6f.firebaseapp.com';
export const FIREBASE_PROJECT_ID = 'pub365-aec6f';
export const FIREBASE_DATABASE_URL = 'https://pub365-aec6f.firebaseio.com';
export const FIREBASE_STORAGE_BUCKET = 'pub365-aec6f.appspot.com';
export const FIREBASE_MESSAGING_SENDER_ID = '757722291845'
export const ALGOLIA_APP_ID = '3C4IBBEYIU'
export const ALGOLIA_KEY= '9c262c5eb5039c372b19b90e7e2dd4e0';
export const ROLLBAR_TOKEN= '7e782253b9e242b4a6dba5aae3df1ac4';



export const CURRENT_USER_EMAIL = 'current_user_email';
export const CURRENT_USER = 'current_user';
export const CURRENT_USER_ID = 'current_user_id';
export const PROFILES_INDEX = 'profiles';
export const BEERS_INDEX = 'beers';
export const PUB_LOCATION: string = 'pub365_location';
export const ONE_HOUR_CACHE: number = 3600;
export const CURRENT_USER_LOCATION = 'pub365_user_location';
export const MINUTE_IN_MILLISECONDS = 60000;
export const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS;
export const DAY_IN_MILLISECONDS = 24 * HOUR_IN_MILLISECONDS;


export const COLLECTIONS = {
    PROFILES: 'profiles',
    BEERS: 'beers',
    ORDERS: 'orders',
    MILESTONES: 'milestones',
    FOOTER_EVENTS: 'footerEvents',
    CONFIG: 'config',
    CATEGORIES: 'categories',
    EVENTS: 'events'
}

export const enum AUTH_STATUS  {
    UNKNOWN,
    LOGGED_OUT,
    LOGGED_IN,
    USER_LOADED,
    SIGN_IN_STARTED,
    SIGN_IN_COMPLETE,
    SIGN_IN_FAILED,
    CREATE_PROFILE_STARTED,
    CREATE_PROFILE_COMPLETE,
    CREATE_PROFILE_FAILED,
    PROFILE_LOADED,
    EDIT_PROFILE_STARTED,
    EDIT_PROFILE_COMPLETE,
    EDIT_PROFILE_FAILED,
    SIGN_OUT

}

export enum WishlistActions {
    ADD = 1,
    REMOVE = 2,
}

export enum ORDER_STATUS {
    PENDING = 1,
    TRIED = 2,
    DEFAULT = 3
}

export enum OrderActions {
    ADD = 1,
    REMOVE = 2
}

export enum STATUS {
    ACTIVE = 1,
    BLOCKED = 2,
    DELETED = 3
}

export enum ROLE {
    CUSTOMER = 1,
    BARMAN = 2,
    ADMIN = 3
}

export enum MILESTONE {
    CLAIMED = 1,
    OUTDATED = 2
}

export enum CLAIM_ACTION {
    ALREADY_CLAIMED = 0,
    SUCCEED = 1,
    FAILED = 3
}


export enum NEWS_FEED_TYPE {
    NEW_USER = 1,
    BEER_ORDER = 2,
    ACHIEVE_MILESTONE = 3,
}