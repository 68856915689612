import {createAsyncThunk} from "@reduxjs/toolkit";
import {AlgoliaApi} from "@services";

export const getBarmen = createAsyncThunk('database/getBarmen', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try{
        const barmen = await AlgoliaApi.getBarmen();
        return fulfillWithValue(barmen)
    }catch(error: any){
        throw rejectWithValue(error.message)
    }
});