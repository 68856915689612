import React, { useEffect, useState } from 'react';
import {
    Button, ButtonBase,
    Card,
    CardContent, Dialog,
    Grid,
    Link, Modal,
    Paper,
    Typography,
} from '@mui/material';
import {selectMilestones, selectUser} from "@store";
import {useSelector} from "react-redux";
import {RewardItem} from "./RewardItem";
import {colorPrimary, GlobalStyles} from "@design";

// Define a type for your reward data
type Reward = {
    uid: string;
    name: string;
    description: string;
    thumbnail: string;
    milePercentage: number;
    userPoints: number;
    point: number;
    mileStatus: number;
};


export function RewardsScreen() {
    const milestones = useSelector(selectMilestones);
    const profile = useSelector(selectUser);
    const [rewards, setRewards]: any = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedReward, setSelectedReward]: any = useState(null);

    const showModal = (reward: any) => {
        setVisible(true);
        setSelectedReward(reward);
    };
    const hideModal = () => {
        setVisible(false);
        setSelectedReward(null);
    };

    useEffect(()=> {
        let initRewards: any[] = [];
        if(profile && profile.uid) {
            milestones.forEach((milestone: any) => {
                const userMilestones = profile.milestones ? profile.milestones : [];
                let milePercentage = milestone.point > profile.point ? (profile.point * 100) / milestone.point : 100;
                if (!rewards.some((reward: any) => reward.uid == milestone.uid)) {
                    let mile = userMilestones.find((userMile: any) => userMile.mileUid == milestone.uid);
                    let achievedStatus = mile && mile.mileUid && mile.status ? mile.status : 0;
                    initRewards.push({
                        uid: milestone.uid,
                        name: milestone.name,
                        description: milestone.description,
                        thumbnail: milestone.thumbnail,
                        milePercentage: milePercentage,
                        userPoints: profile.point,
                        point: milestone.point,
                        mileStatus: achievedStatus,
                    });
                    initRewards = initRewards.sort((a, b) => a.point < b.point ? -1 : a.point > b.point ? 1 : 0);
                    setRewards(initRewards);
                }
            });
        }
    }, [milestones, profile && profile.point, profile && profile.uid]);

    return (
        <div>
            <Typography m={2} color="primary" textAlign="center" justifyContent="center" variant="h4">
                Rewards
            </Typography>
            <Grid container>
                {rewards?.map((reward: Reward, index: number) => (
                    <ButtonBase onClick={()=> setSelectedReward(reward)}>
                   <RewardItem reward={reward} />
                    </ButtonBase>
                ))}
            </Grid>

            {selectedReward && (
                <Dialog open={!!selectedReward}
                        sx={{background: 'transparent',
                           ' .MuiPaper-root': {
                            'background-color': 'transparent'
                        }}}
                       onClose={hideModal}>
                    <Grid style={{ ...GlobalStyles.viewBackground, padding: 20, textAlign: 'center', borderRadius: '10px', border: `solid ${colorPrimary} 4px`, overflow: 'hidden' }}>
                        <Typography mb={2} color="primary" variant="h6">{selectedReward.name}</Typography>
                        <Typography color="secondary" variant="subtitle1">{selectedReward.description}</Typography>
                        {/* Add more reward details here */}
                    </Grid>
                </Dialog>
            )}
        </div>
    );
};

export default RewardsScreen;
