import {createUserWithEmailAndPassword} from 'firebase/auth';
import {NavLink, useNavigate} from "react-router-dom";
import {ReactNode, useEffect, useState} from "react";
import {AppInput, GlobalStyles} from "@design";
import {combine} from "@helpers";
import Grid from '@mui/material/Unstable_Grid2';
import {
    Button, Dialog, DialogTitle,
    FormControl, FormHelperText,
    IconButton,
    InputAdornment, InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme
} from "@mui/material"; // Grid version 2
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import {ArrowDropDownIcon, LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useDispatch, useSelector} from "react-redux";
import {selectAuthErrors, selectStatus} from "@store";
import {SignUpParams} from "@types";
import {signUp} from "@store/thunks/auth.methods";
import {ShowAlert} from "@shared";
import {AlgoliaApi, FirestoreApi} from "@services";
import {AUTH_STATUS} from "@consts";
import {ErrorMessage, Form, Formik, FormikHelpers} from 'formik';
// @ts-ignore
import {signUpValidationSchema} from "@validators";
import {firebaseAuth} from "@app-config";
import {Assets} from "@assets";
import BeerOutlinedIcon from '@mui/icons-material/SportsBarOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import {LogoMain} from "@components";
import {useSnackbar} from "notistack";


type SignUpScreenProps = {
    onRegisterCallback?: (data: any) => any
}

export function SignUpScreen({onRegisterCallback}: SignUpScreenProps) {
    const dispatch = useDispatch<any>();
    const status = useSelector(selectStatus);
    const [loading, setLoading] = useState(false);
    const errorMessage = useSelector(selectAuthErrors);
    const {enqueueSnackbar} = useSnackbar();
    const [barmans, setBarmans] = useState<{ label: string, value: string, id: string }[]>([]);
    const initialValues: SignUpParams = {
        email: '',
        password: '',
        barman: '',
        birthday: '',
        firstName: '',
        lastName: '',
        phone: '',
        confirmPassword: ''
    };


    async function onSubmit({
                                email,
                                password,
                                barman,
                                birthday,
                                firstName,
                                lastName,
                                phone,
                                confirmPassword
                            }: SignUpParams, {resetForm}: FormikHelpers<any>) {
        setLoading(true);
        if (onRegisterCallback) {
            const resp = await FirestoreApi.registerUser({
                 email,
                 password,
                 barman,
                 birthday,
                 firstName,
                 lastName,
                 phone,
                 confirmPassword
             });
             onRegisterCallback(resp);
            setLoading(false);
            resetForm({});
        } else {
            const {payload} = await dispatch(signUp({
                email,
                firstName,
                lastName,
                birthday: birthday && birthday.toDate(),
                phone,
                barman,
                password,
                confirmPassword
            }));
        }
        // ShowAlert(payload);
    }

    useEffect(() => {
        AlgoliaApi.getBarmen().then((barmens) => {
            const barmanOptions = barmens.map(({uid, fullName}: { uid: string, fullName: string }) => ({
                label: fullName,
                value: uid,
                id: uid
            }));
            setBarmans(barmanOptions as any);
        }).catch((error) => {
            // show error.
        });
    }, []);

    useEffect(() => {
        // TODO change to switch case.
        if (status === AUTH_STATUS.PROFILE_LOADED) {
            // @ts-ignore
            // navigation.reset({
            //     index: 0,
            //     routes: [{name: 'Home'}],
            // });
        } else if (status === AUTH_STATUS.CREATE_PROFILE_FAILED) {
            enqueueSnackbar(errorMessage);
        }
    }, [status]);


    const navigate = useNavigate();
    const theme: any = useTheme();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const onSubmit1 = async (e: any) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(firebaseAuth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });


    }

    return (
        <>
            <Formik
                validationSchema={signUpValidationSchema}
                initialValues={initialValues}
                onSubmit={(v, formikHelpers) => onSubmit(v, formikHelpers)}>
                {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      isValid,
                      setFieldValue,
                      setFieldTouched,
                      resetForm
                  }) => {
                    return (
                        <Grid container spacing={2} columns={1}>
                            <Grid sx={{mt: 5}} xs={12} display="flex" flexDirection="column" justifyContent="center"
                                  alignItems="center">
                                {/*<img style={combine(GlobalStyles.smallImg)} src={Assets.appLogo} alt='logo'/>*/}
                                <Typography variant="h4" style={theme.headline}>Register</Typography>
                            </Grid>
                            <Grid xs={12} display="flex" justifyContent="center" alignItems="center">

                                <Form style={combine(GlobalStyles.bp, GlobalStyles.fullWidth)}>
                                    <Grid mb={1}>
                                        <TextField fullWidth
                                                   error={!!(errors.firstName && touched.firstName)}
                                                   helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                                   label="First name"
                                                   id="firstName"
                                                   onBlur={() => setFieldTouched('firstName', true)}
                                                   onChange={handleChange}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <PersonOutlinedIcon color="secondary"/>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                        />
                                        {errors.email && touched.email ? <div>{errors.email}</div> : null}
                                    </Grid>
                                    <Grid mb={1}>
                                        <TextField fullWidth
                                                   label="Last name"
                                                   id="lastName"
                                                   onBlur={() => setFieldTouched('lastName', true)}
                                                   error={!!(errors.lastName && touched.lastName)}
                                                   helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                                   onChange={handleChange}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <PersonOutlinedIcon color="secondary"/>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                        />
                                    </Grid>
                                    <Grid mb={1}>
                                        <TextField fullWidth
                                                   label="Email"
                                                   id="email"
                                                   type="email"
                                                   onBlur={() => setFieldTouched('email', true)}
                                                   error={!!(errors.email && touched.email)}
                                                   helperText={errors.email && touched.email ? errors.email : ''}
                                                   onChange={handleChange}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <EmailOutlinedIcon color="secondary"/>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                        />
                                    </Grid>
                                    <Grid mb={1}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker label="Birthday"
                                                              onChange={(value) => {
                                                                  setFieldValue('birthday', value);
                                                              }}
                                                              slotProps={{
                                                                  layout: {
                                                                      sx: {
                                                                          '& .MuiPickersCalendarHeader-labelContainer': {
                                                                              color: 'black'
                                                                          }
                                                                      }
                                                                  },
                                                                  textField: {
                                                                      onBlur: () => setFieldTouched('birthday', true),
                                                                      helperText: errors.birthday && touched.birthday ? errors.birthday as string : '',
                                                                      error: !!(errors.birthday && touched.birthday),
                                                                      fullWidth: true,
                                                                      InputProps: {
                                                                          startAdornment: (
                                                                              <InputAdornment position="start">
                                                                                  <CalendarMonthOutlinedIcon
                                                                                      color="secondary"/>
                                                                              </InputAdornment>
                                                                          ),
                                                                      }
                                                                  }
                                                              }}/>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid mb={1}>
                                        <TextField fullWidth
                                                   label="Phone"
                                                   id="phone"
                                                   onBlur={() => setFieldTouched('phone', true)}
                                                   error={!!(errors.phone && touched.phone)}
                                                   helperText={errors.phone && touched.phone ? errors.phone : ''}
                                                   onChange={handleChange}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <PhoneAndroidOutlinedIcon color="secondary"/>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                        />
                                    </Grid>

                                    <Grid mb={1}>
                                        <TextField fullWidth
                                                   label="password"
                                                   id="password"
                                                   type="password"
                                                   onBlur={() => setFieldTouched('password', true)}
                                                   error={!!(errors.password && touched.password && touched.confirmPassword)}
                                                   helperText={errors.password && touched.password && touched.confirmPassword ? errors.password : ''}
                                                   onChange={handleChange}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <LockOpenOutlinedIcon color="secondary"/>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                        />
                                    </Grid>

                                    <Grid mb={1}>
                                        <TextField fullWidth
                                                   label="confirmPassword"
                                                   id="confirmPassword"
                                                   type="password"
                                                   onBlur={() => setFieldTouched('confirmPassword', true)}
                                                   error={!!(errors.confirmPassword && touched.confirmPassword && touched.password)}
                                                   helperText={errors.confirmPassword && touched.confirmPassword && touched.password ? errors.confirmPassword : ''}
                                                   onChange={handleChange}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <LockOpenOutlinedIcon color="secondary"/>
                                                           </InputAdornment>
                                                       ),
                                                   }}
                                        />
                                    </Grid>

                                    <Grid mb={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="barman">Bartender</InputLabel>
                                            <Select
                                                labelId="barman"
                                                onBlur={() => setFieldTouched('barman', true)}
                                                error={!!(errors.barman && touched.barman)}
                                                onChange={({target}) => setFieldValue('barman', target.value)}
                                                autoWidth
                                                label="Bartender"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <BeerOutlinedIcon color="secondary"/>
                                                    </InputAdornment>
                                                }
                                            >
                                                {barmans.map((barman) => (
                                                    <MenuItem id={barman.id}
                                                              value={barman.value}>{barman.label}</MenuItem>))}
                                            </Select>
                                            {errors.barman && touched.barman &&
                                            <FormHelperText sx={{color: 'red'}}>{errors.barman}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid mb={1}>
                                        <Button sx={{borderRadius: 20}} color="primary" variant="contained" fullWidth
                                                onClick={() => handleSubmit()}>
                                            <Typography fontWeight={600} fontSize={18}>
                                                {loading ? 'Loading...' : 'Register'}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Form>
                            </Grid>
                        </Grid>
                    )
                }}
            </Formik>
            <Dialog
                open={loading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Saving..."}
                </DialogTitle>
            </Dialog>
        </>
    );
}