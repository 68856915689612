import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Typography,
    Grid,
    useTheme,
} from "@mui/material";
import { resetTopUsers, selectTopUsers, selectTopUsersLoading } from "@store";
import { getTopUsers } from "@store/thunks/get-top-users";
import { Profile } from "@types";
import { GlobalStyles } from "@design";
import { truncate } from "@shared";
import {combine} from "@helpers";
import { Virtuoso } from "react-virtuoso";
import {Assets} from "@assets";

interface UsersLeaderboardProps {
    user: Profile;
    rank: number;
}

const UsersLeaderboardItem: React.FC<UsersLeaderboardProps> = ({
                                                                   user,
                                                                   rank,
                                                               }) => {
    return (
        <Grid container display="flex" alignItems="center" mb={1} sx={{padding: '10px 0 10px 0', backgroundColor: 'rgba(0, 0, 0, 0.36)'}}>
            <Grid item xs={2}>
                <Typography
                    variant="h5"
                    style={{
                        color: "orange",
                        textAlign: "center",
                    }}
                >
                    {rank}
                </Typography>
            </Grid>
            <Grid item xs={7} display="flex" alignItems="center">
                <div style={combine(GlobalStyles.usersList.thumbnail, GlobalStyles.usersList.thumbnailSize)}>
                    {!user.thumbnail || user.thumbnail.includes("default") ? (
                        <img
                            src={Assets.defaultThumbnail}
                            alt="User Thumbnail"
                            style={GlobalStyles.usersList.thumbnailSize}
                        />
                    ) : (
                        <img
                            src={user.thumbnail}
                            alt="User Thumbnail"
                            style={GlobalStyles.usersList.thumbnailSize}
                        />
                    )}
                </div>
                <Typography
                    pl={2}
                    fontWeight="bold"
                    variant="body2"
                    color="secondary">
                    {truncate(user.fullName, 14)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    fontWeight="bold"
                    variant="body1"
                    color="secondary">
                    {user.point} pts
                </Typography>
            </Grid>
        </Grid>
    );
};

export function UsersLeaderboard() {
    const topUsers: any = useSelector(selectTopUsers);
    const topUsersLoading: boolean = useSelector(selectTopUsersLoading);
    const dispatch = useDispatch<any>();

    const theme = useTheme();

    function getItem(data: any, index: number) {
        return data && data[index];
    }

    function getNextTopUsers() {
        if (!topUsersLoading) {
            dispatch(getTopUsers());
        }
    }

    useEffect(() => {
        getNextTopUsers();
        return () => dispatch(resetTopUsers());
    }, []);

    return (
        <Grid>
            <Virtuoso
                style={{height: window.innerHeight - 65}}
                data={topUsers}
                endReached={getNextTopUsers}
                overscan={50}
                itemContent={(index, user) => (
                    <UsersLeaderboardItem  user={user} rank={index + 1} />
                )}
            />
        </Grid>
    );
}