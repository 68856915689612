import {FirestoreApi, GeolocationService} from "@services";


export async function orderByLocationCondition(orderedBeer: any, profile? : any) {
    let response: any = {success: true, error: null};
    const isInLocation = await GeolocationService.isInLocation();
    if(isInLocation) {
       const orderSucceed =  FirestoreApi.orderBeer(orderedBeer, profile);
       if(!orderSucceed) {
           response.success = false;
           response.error = 'Order failed. please contact support'
       }
    } else {
        response.success = false;
        response.error = 'You are too far from our bar, please come in to order our beers!'
    }
    return response;
}