import {useCallback, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {selectAuthErrors, selectStatus} from "@store";

import {useSnackbar} from "notistack";
import {SignUpScreen} from "@screens";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";


export function SignUpScreenUser() {
    const { enqueueSnackbar } = useSnackbar();
    const createFromBackend = useCallback((response: any)=> {
        enqueueSnackbar("User registered.");
        window.location.reload();
    }, []);

    return (
        <>
            <div style={{maxWidth: 600, margin: 'auto auto'}}>
                <SignUpScreen onRegisterCallback={createFromBackend}/>
            </div>
        </>
    );
}