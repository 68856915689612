import React from 'react';
import {
    Card,
    CardContent,
    Grid, LinearProgress, Typography,
} from '@mui/material';
import {Assets} from "@assets";
import {GlobalStyles} from "@design";

type RewardItemProps = {
    reward: {
        thumbnail: string;
        name: string;
        description: string;
        userPoints: number;
        point: number;
        milePercentage: number;
    };
    theme: any;
};

export function RewardItem({ reward }: any) {
    return (
        <Card sx={{background: 'rgb(0 0 0 / 35%);', marginBottom: 3, width: '100%'}}>
            <CardContent>
                <Grid container display="flex" spacing={2}>
                    <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
                        <img
                            src={reward.thumbnail}
                            alt={reward.name}
                            style={{ width: "100%", maxWidth: '80px' }}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign="left" variant="h6" color="secondary">
                            {reward.name}
                        </Typography>
                        <Typography textAlign="left" variant="subtitle2" color="secondary">
                            {reward.description.substring(0, 25)}
                        </Typography>
                        <div style={{ marginTop: 10 }}>
                            <Typography textAlign="left" variant="subtitle1" color="secondary">
                                {`${reward.userPoints}/${reward.point}`}
                            </Typography>
                            <LinearProgress
                                style={{ backgroundColor: '#ffbc3a' }}
                                variant="determinate"
                                value={(reward.userPoints / reward.point) * 100}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                        <div>
                            {reward.milePercentage >= 100 ? (
                                <img
                                    src={Assets.fullStar}
                                    alt="Full Star"
                                    style={GlobalStyles.rewardStarIcon}
                                />
                            ) : (
                                <img
                                    src={Assets.emptyStar}
                                    alt="Empty Star"
                                    style={GlobalStyles.rewardStarIcon}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

