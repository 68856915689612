import {createSelector, createSlice} from '@reduxjs/toolkit';
import {getFooterEvents} from "@store/thunks/get-footer-events";
import {RootState} from "@reduxjs/toolkit/dist/query/core/apiState";
import {getMilestones} from "@store/thunks/get-milestones";
import {getTopUsers} from "@store/thunks/get-top-users";
import {getFacets} from "@store/thunks/get-facets";
import {getCategories} from "@store/thunks/get-categories";
import {getEvents} from "@store/thunks/get-events";

const initialState = {
    footerEvents: [],
    milestones: [],
    topUsers: [],
    topUsersPage: -1,
    topUsersLoading: false,
    loading: false,
    beerFacets: [],
    beerFacetsLoading: false,
    categories: [],
    events: [],
    categoriesLoading: false,
}
export const databaseSlice = createSlice({
    name: 'database',
    initialState,
    reducers: {
        resetTopUsers: (state) => {
            state.topUsersPage = -1;
            state.topUsers = [];
            state.topUsersLoading = false;
        }
    },
    extraReducers: builder => {
        builder.addCase(getFooterEvents.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getFooterEvents.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getFooterEvents.fulfilled, (state, action) => {
            state.footerEvents = action.payload || [];
            state.loading = false;
        });

        builder.addCase(getEvents.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getEvents.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getEvents.fulfilled, (state, action) => {
            state.events = (action.payload || []) as any;
            state.loading = false;
        });

        builder.addCase(getMilestones.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getMilestones.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getMilestones.fulfilled, (state, action) => {
            state.milestones = action.payload || [];
            state.loading = false;
        });

        builder.addCase(getTopUsers.pending, (state, action) => {
            state.topUsersLoading = true;
        });
        builder.addCase(getTopUsers.rejected, (state, action) => {
            state.topUsersLoading = false;
        });
        builder.addCase(getTopUsers.fulfilled, (state, action) => {
            const {page, users} = action.payload || {};
            state.topUsersLoading = false;
            state.topUsersPage = page;
            state.topUsers = state.topUsers.concat(users as any);
        });

        builder.addCase(getFacets.pending, (state, action) => {
            state.beerFacetsLoading = true;
        });
        builder.addCase(getFacets.rejected, (state, action) => {
            state.beerFacetsLoading = false;
        });
        builder.addCase(getFacets.fulfilled, (state, action) => {
            state.beerFacetsLoading = false;
            state.beerFacets = action.payload || {};
        });

        builder.addCase(getCategories.pending, (state, action) => {
            state.categoriesLoading = true;
        });
        builder.addCase(getCategories.rejected, (state, action) => {
            state.categoriesLoading = false;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categoriesLoading = false;
            // @ts-ignore
            state.categories = action.payload || {};
        });
    }
});

const selectDomain = (state: RootState<any, any, any>) => state.database || initialState;

export const {resetTopUsers} = databaseSlice.actions;

// selectors
export const selectFooterEvents = createSelector(
    [selectDomain],
    (state: any) => state && state.footerEvents || []
);

export const selectEvents = createSelector(
    [selectDomain],
    (state: any) => state && state.events || []
);

export const selectMilestones = createSelector(
    [selectDomain],
    (state: any) => state && state.milestones || []
);

export const selectTopUsers = createSelector(
    [selectDomain],
    (state: any) => state && state.topUsers || []
);

export const selectTopUsersLoading = createSelector(
    [selectDomain],
    (state: any) => state && state.topUsersLoading
);

export const selectBeerFacets = createSelector(
    [selectDomain],
    (state: any) => state && state.beerFacets
);

export const selectBeerCategories = createSelector(
    [selectDomain],
    (state: any) => state && state.categories
);


export const selectFooterEventsIsLoading = createSelector(
    [selectDomain],
    (state: any) => state && state.loading
);

export const databaseReducer = databaseSlice.reducer;