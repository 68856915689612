import {Grid, IconButton} from "@mui/material";
import {Assets} from "@assets";
import {GlobalStyles} from "@design";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectMenuOpen, setMenuOpen} from "@store";
import {hideMenu, isSecuredRoute} from "@shared";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";


export function AppHeader() {
    const [isMenuHidden, setHideMenu] = useState(false);
    const dispatch = useDispatch<any>();
    const menuOpen: boolean = useSelector(selectMenuOpen);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(()=> {
        setHideMenu(hideMenu(location.pathname));
    }, [location.pathname]);
    return (
        <Grid container position="relative" display="flex" justifyContent="center" alignItems="center">
            { isSecuredRoute(location.pathname) ?
                isMenuHidden ? "" :
                <IconButton style={GlobalStyles.menuIcon} onClick={()=> dispatch(setMenuOpen(!menuOpen))}>
                <MenuIcon />
            </IconButton>
                : <IconButton onClick={()=> navigate(-1)} style={GlobalStyles.menuIcon}><ArrowBackIcon/></IconButton>}
            <img src={Assets.headerLogo} style={GlobalStyles.logoMain(60, 20, 10)} />
        </Grid>
)
}