import {createAsyncThunk} from "@reduxjs/toolkit";
import {AlgoliaApi} from "@services";


export const getBeers = createAsyncThunk('beerOrders/getBeers', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
        const beers: any[] = await AlgoliaApi.getAllActiveBeers();
        return fulfillWithValue(beers);
    } catch(error: any) {
        throw rejectWithValue(error.message);
    }
});