import React, { useEffect, useState } from 'react';
import {Button, Modal, TextField, Box, Grid, Typography, Dialog} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getBeers } from '@store/thunks/get-beers';
import { pendingBeersCount, selectBeers } from '@store';
import {isDefined, isPercentageNumber} from "@shared";
import {EmptyMessage} from "@components";
import {Assets} from "@assets";
import {BeerItem} from "./BeerItem";
import { Virtuoso } from 'react-virtuoso';
import {Beer} from "@types";

type BeerListProps = {
    filterCallback?: any;
    data?: Beer[];
};

export function BeerList({ filterCallback, data }: BeerListProps) {
    const [visible, setVisible] = useState(false);
    const [filter, setFilter] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const totalPending = useSelector(pendingBeersCount);
    const [beers, setBeers] = useState<any[]>([]);
    const [beersAll, setBeersAll] = useState<any[]>([]);
    const items: any = useSelector(selectBeers);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (filterCallback) {
            const beers1 = (items || []).filter((beer: any) => filterCallback(beer));
            setBeersAll(beers1);
            setBeers(beers1);
        } else {
            setBeersAll(items || []);
            setBeers(items || []);
        }
    }, [items]);

    useEffect(() => {
        getWishlist();
    }, []);

    // useEffect(() => {
    //     const rootNavigation = navigation.getParent ? navigation.getParent() : navigation;
    //     rootNavigation.setOptions({
    //         headerLeft: () => (
    //             <Button
    //                 startIcon={<ArrowBackIcon />}
    //                 onClick={() => {
    //                     onReset();
    //                     navigation.goBack();
    //                 }}
    //             >
    //                 Back
    //             </Button>
    //         ),
    //         headerRight: () => (
    //             <Button startIcon={<FilterIcon />} onClick={() => setVisible(!visible)}>
    //                 Filter
    //             </Button>
    //         ),
    //     });
    // }, [route, items]);

    function getWishlist() {
        dispatch(getBeers());
    }

    function filterList(text: string) {
        onReset();
        const term = (text || '').toLowerCase();
        if (!text) {
            setBeers(beersAll);
        } else {
            const percentage = isPercentageNumber(text);
            if (percentage) {
                const filteredBeers = beersAll.filter((item) => item.abv >= percentage).sort((a, b) => a.abv - b.abv);
                setBeers(filteredBeers);
            } else {
                const filteredBeers = beersAll.filter((item: any) => item && item.fullTitle.toLowerCase().includes(term));
                setBeers(filteredBeers);
            }
        }
    }

    const onSave = (value: any) => {
        setVisible(false);
        setFilter(value);
        const categories = (value.categories || []).map((category: string) => category.toLowerCase());
        const author = (value.author || []).map((author: string) => author.toLowerCase());
        const style = (value.style || []).map((style: string) => style.toLowerCase());
        const region = (value.region || []).map((region: string) => region.toLowerCase());
        const filteredBeers = beersAll.filter((item: any) => {
            return (
                ((categories && categories.length && item.categories.some((category: string) => categories.includes(category.toLowerCase()))) ||
                    !value.categories ||
                    !value.categories.length) &&
                ((author && author.length && author.some((author: string) => author === item.author.toLowerCase())) ||
                    !value.author ||
                    !value.author.length) &&
                ((style && style.length && style.some((style: string) => style === item.style.toLowerCase())) ||
                    !value.style ||
                    !value.style.length) &&
                ((region && region.length && region.some((region: string) => region === item.region.toLowerCase())) ||
                    !value.region ||
                    !value.region.length) &&
                ((value.min && value.min <= item.abv) || !isDefined(value.min)) &&
                ((value.max && value.max >= item.abv) || !isDefined(value.max))
            );
        });
        setBeers(filteredBeers);
    };

    const onReset = () => {
        setVisible(false);
        setFilter(null);
        setBeers(beersAll);
    };

    return (
        <Box>
            {beers.length === 0 ? (
                <EmptyMessage img={Assets.beerDefault} message="No beers found" />
            ) : (
                <Virtuoso
                    style={{height: window.innerHeight - 65 - 56}}
                    data={data || beers}
                    itemContent={(index, beer) => (
                       <BeerItem totalPending={totalPending} showCount={true} beer={beer} />
                    )}
                />
            )}
            <Box sx={{ width: '100%', position: 'absolute', bottom: 0 }}>
                <TextField
                    variant="outlined"
                    sx={{border: 'none',"& fieldset": { border: 'none' }}}
                    placeholder="Search..."
                    fullWidth
                    onChange={(e) => filterList(e.target.value)}
                />
            </Box>
        </Box>
    );
}
