import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "@reduxjs/toolkit/dist/query/core/apiState";
import {setProfile} from "@store/thunks/auth.methods";
import {getValueByPath} from "@shared";
import {getBeers} from "@store/thunks/get-beers";
import {syncBeersState} from "@store";
import {ORDER_STATUS} from "@consts";
import {getTopObjects} from "@shared";
import {Beer} from "@types";
import {selectedBeer} from "@store/thunks/selected-beer";


type BeerOrdersState = {
    beers: Beer[];
    beersPage: number;
    beersLoading: boolean;
    orders: {[key: string]: ORDER_STATUS};
    wishlist: string[]
    selectedBeer: Beer | null
}


const initialState: BeerOrdersState = {
    beers: [],
    beersPage: -1,
    beersLoading: false,
    orders: {},
    wishlist: [],
    selectedBeer: null
}
export const beerOrderSlice = createSlice({
    name: 'beerOrders',
    initialState,
    reducers: {
        setOrders: (state, action: PayloadAction<any>) => {
            state.orders = action.payload.reduce((acc: any, order: any) => {
                acc[order.beerId] = order.status;
                return acc;
            }, {});
            console.log('sync beers...');
            state.beers = syncBeersState(state.beers, state);
            if(state.selectedBeer) {
                state.selectedBeer = state.beers.find(beer =>  state.selectedBeer && state.selectedBeer.uid === beer.uid) || null;
            }
        },
        setBeers: (state, action: PayloadAction<any>) => {
            state.beers = action.payload || [];
        },
    },
    extraReducers: builder => {
        builder.addCase(setProfile.fulfilled, (state, action) => {
            const wishlist = getValueByPath(action, 'payload.wishlist', []);
            state.wishlist = wishlist;
            console.log('sync beers...');
            state.beers = syncBeersState(state.beers, state);
        });

        builder.addCase(getBeers.pending, (state, action) => {
            state.beersLoading = true;
        });

        builder.addCase(getBeers.rejected, (state, action) => {
            state.beersLoading = false;
        });

        builder.addCase(getBeers.fulfilled, (state, action) => {
            const beers = action.payload || {};
            state.beersLoading = false;
            state.beers = syncBeersState(beers, state);
            if(state.selectedBeer) {
                state.selectedBeer = state.beers.find(beer =>  state.selectedBeer && state.selectedBeer.uid === beer.uid) || null;
            }
        });

        builder.addCase(selectedBeer.fulfilled, (state, action: any) => {
            state.selectedBeer = action.payload;
        });
    }
});

const selectDomain = (state: RootState<any, any, any>) => state.beerOrders || initialState;

export const {setOrders, setBeers} = beerOrderSlice.actions;

// selectors

export const selectBeersLoading = createSelector(
    [selectDomain],
    (state: any) => state && state.beersLoading
);

export const selectBeers = createSelector(
    [selectDomain],
    (state: any) => state && state.beers
);

export const selectUntriedBeers = createSelector(
    [selectDomain],
    (state: any) => state && state.beers.filter((beer: any)=> beer.status !== ORDER_STATUS.TRIED)
);

export const selectTriedBeers = createSelector(
    [selectDomain],
    (state: any) => state && state.beers.filter((beer: any)=> beer.status === ORDER_STATUS.TRIED)
);

export const selectWishlist = createSelector(
    [selectDomain],
    (state: any) => state && state.beers.filter((beer: any)=> beer.wishlist)
);

export const selectSelectedBeer = createSelector(
    [selectDomain],
    (state: any) => state && state.selectedBeer
);

export const selectTop20Beers = createSelector(
    [selectDomain],
    (state: any) => getTopObjects(state && state.beers, 'counter', 20)
);

export const pendingBeersCount = createSelector(
    [selectDomain],
    (state: any) => (state && state.beers || []).filter((beer: Beer) => beer.orderStatus === ORDER_STATUS.PENDING).length
);

export const beerOrdersReducer = beerOrderSlice.reducer;