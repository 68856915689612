import {createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from "@reduxjs/toolkit/dist/query/core/apiState";

const initialState = {
    menuOpen: false
}

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setMenuOpen: (state, action)=> {
            state.menuOpen = action.payload;
        }
    },
    extraReducers: builder => {
    }
});

export const { setMenuOpen } = generalSlice.actions;

// selectors
const selectDomain = (state: RootState<any, any, any>) => state.general || initialState;

export const selectMenuOpen = createSelector(
    [selectDomain],
    (state: any) => state && state.menuOpen
);

export const generalReducer = generalSlice.reducer;