import {useDispatch, useSelector} from "react-redux";
import {appReady, selectIsReady, selectUid, selectUser} from "@store";
import React, {useEffect, useState} from "react";
import {getMilestones} from "@store/thunks/get-milestones";
import {useOnOrdersChange} from "../hooks/useOnOrdersChange";
import {setOrders} from "@store";
import {setProfile, signOut} from "@store/thunks/auth.methods";
import {getFacets} from "@store/thunks/get-facets";
import {getCategories} from "@store/thunks/get-categories";
import {getBeers} from "@store/thunks/get-beers";
import {getEvents} from "@store/thunks/get-events";
import {NotificationService} from "@services";
import {ThemeProvider} from "@mui/material";
import {theme} from "../theme/theme";
import {AppRouter, Screens} from "@routes";
import {Profile} from "@types";
import {useAuthStateChanged, useOnProfileChange} from "@hooks";
import {STATUS} from "@consts";
import {AppDrawer} from "./AppDrawer";
import {AppHeader} from "./AppHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {isSecuredRoute} from "@shared";
console.log('version 8....');
let cop: any = null;
export function Shell() {
    const isReady = useSelector(selectIsReady);
    const uid =  useSelector(selectUid);
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location ', location);
    const { enqueueSnackbar } = useSnackbar();


    // useRollbarPerson({uid});

    useEffect(()=> {
        if (isReady && !uid && isSecuredRoute(location.pathname)) {
            navigate(Screens.SignIn);
        } else if(isReady && uid && !isSecuredRoute(location.pathname)) {
            navigate(Screens.Home);
        }
    }, [isReady, uid, location.pathname]);
    const dispatch = useDispatch<any>();
    useOnProfileChange(uid, async (profile: any) => {
        if(!cop) {
            cop = profile;
        } else {
            let diff: any = {};
           Object.keys(cop).forEach((key)=> {
               if(cop[key] != profile[key]) {
                   diff[key] = {
                       old: cop[key],
                       new: profile[key]
                   }
               }
           });
           console.log('DIFFFF', diff);
        }
        if(profile && profile.status !== STATUS.ACTIVE) {
            dispatch(signOut());
            enqueueSnackbar('Account is blocked, please contact support');
        }
        // NotificationService.verifyNotificationAndReturnToken(profile);
        dispatch(setProfile(profile));
        dispatch(getBeers());
    });

    // TODO add web notification
    // useNotification(uid, (type: string, message: any)=> {
    //     const title = message && message.data && message.data.title;
    //     const body = message && message.data && message.data.body;
    //     const isEvent = message && message.data && message.data.isEvent;
    //     setNotification({title, body});
    //     if(body || title) {
    //         setVisible(true);
    //     }
    // });

    useOnOrdersChange(uid, (orders)=> {
        dispatch(setOrders(orders));
    });

    useAuthStateChanged();

    useEffect(()=> {
        dispatch(getMilestones());
        dispatch(getFacets())
        dispatch(getCategories())
        dispatch(getEvents())
    }, []);

    //loading
    if (!isReady) return null;
    return <ThemeProvider theme={theme}>
        <AppHeader/>
        <AppRouter/>
        <AppDrawer/>
    </ThemeProvider>
}