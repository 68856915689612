
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export function getDateFormat(dateStr: string, format: string): string {
    const date = new Date(dateStr);
    let formattedDate = dateStr;
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate().toString().padStart(2, '0');
    switch (format) {
        case 'MMMM yyyy': {
            formattedDate = `${months[monthIndex]} ${year}`;
            break;
        }
        case 'dd MMM yyyy': {
            formattedDate = `${months[monthIndex]} ${day} ${year}`;
            break;
        }
    }
    return formattedDate as string;
}

export function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const monthNames: string[] = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthIndex: number = date.getMonth();
    const day: number = date.getDate();
    const year: number = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
}

export function formatTime(dateString: string): string {
    const date = new Date(dateString);

    let hours: number = date.getHours();
    const minutes: number = date.getMinutes();
    const ampm: string = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const formattedHours: string = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}