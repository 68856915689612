import {createUserWithEmailAndPassword} from 'firebase/auth';
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {GlobalStyles} from "@design";
import {combine} from "@helpers";
import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme
} from "@mui/material"; // Grid version 2
import {useDispatch, useSelector} from "react-redux";
import {selectStatus} from "@store";
import {SignInParams} from "@types";
import {signIn} from "@store/thunks/auth.methods";
import {ShowAlert} from "@shared";
import {AlgoliaApi} from "@services";
import {AUTH_STATUS} from "@consts";
import {ErrorMessage, Form, Formik} from 'formik';
import {signInValidationSchema} from "@validators";
import {firebaseAuth} from "@app-config";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import {LogoMain} from "@components";
import {Screens} from "@routes";

type SignInScreenProps = {
    createFromBackend?: string
}


export function SignInScreen({createFromBackend}: SignInScreenProps) {
    const dispatch = useDispatch<any>();
    const status = useSelector(selectStatus);
    const [barmans, setBarmans] = useState<{ label: string, value: string, id: string }[]>([]);
    const initialValues: SignInParams = {
        email: '',
        password: '',
    };

    async function onSubmit({email, password}: SignInParams) {
        console.log('onSubmit');
        const {payload} = await dispatch(signIn({email, password}));
        if (payload && payload.uid) {
            // navigate(`/${Screens.Home}`);
        }
    }

    useEffect(() => {
        AlgoliaApi.getBarmen().then((barmens) => {
            const barmanOptions = barmens.map(({uid, fullName}: { uid: string, fullName: string }) => ({
                label: fullName,
                value: uid,
                id: uid
            }));
            setBarmans(barmanOptions as any);
        }).catch((error) => {
            // show error.
        });
    }, []);

    useEffect(() => {
        // TODO change to switch case.
        if (status === AUTH_STATUS.PROFILE_LOADED) {
            // @ts-ignore
            // navigation.reset({
            //     index: 0,
            //     routes: [{name: 'Home'}],
            // });
        } else if (status === AUTH_STATUS.CREATE_PROFILE_FAILED) {

        }
    }, [status]);


    const navigate = useNavigate();
    const theme: any = useTheme();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const onSubmit1 = async (e: any) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(firebaseAuth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
    }

    return (
        <Formik
            validationSchema={signInValidationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {({handleChange, handleBlur, handleSubmit, values, errors, touched, isValid, setFieldValue}) => (
                <Grid container spacing={2} columns={1}>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <LogoMain />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">

                        <Form style={combine(GlobalStyles.bp, GlobalStyles.fullWidth)}>
                            <Grid mb={2}>
                                <TextField fullWidth
                                           label="Email"
                                           id="email"
                                           type="email"
                                           onChange={handleChange}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <EmailOutlinedIcon color="secondary"/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                />
                            </Grid>

                            <Grid mb={2}>
                                <TextField fullWidth
                                           label="password"
                                           id="password"
                                           type="password"
                                           onChange={handleChange}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <LockOpenOutlinedIcon color="secondary"/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                />
                            </Grid>
                            <Grid mb={4}>
                                <Button sx={{borderRadius: 20, height: 50}} color="primary" variant="contained" fullWidth onClick={() => handleSubmit()}>
                                    <Typography fontWeight={600} fontSize={18}>Login</Typography>
                                </Button>
                            </Grid>
                            <Grid sx={{pl: 1, pr: 1}}  display="flex" justifyContent="center" alignItems="center" container>
                                <Grid item xs={6}>
                                    <Link to={`/${Screens.SignUp}`} style={GlobalStyles.linkButton}>
                                        <Typography textAlign="left" fontWeight={400} fontSize={14}>REGISTER</Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link to={`/${Screens.ForgotPassword}`} style={GlobalStyles.linkButton}>
                                        <Typography textAlign="right" fontWeight={400} fontSize={14}>FORGOT PASSWORD</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>

            )}
        </Formik>
    );
}