import {createAsyncThunk} from "@reduxjs/toolkit";
import {AlgoliaApi} from "../../services";


export const getTopUsers = createAsyncThunk('database/getTopUsers', async ( _,{dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try{
        const {database}: any = getState();
        const response = await AlgoliaApi.getTop20Profiles(database.topUsersPage + 1);
        return fulfillWithValue(response);
    }catch(error: any){
        throw rejectWithValue(error.message)
    }
});